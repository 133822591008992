.badge {
  // width: 38px;
  padding: 0 9px;
  height: 22px;
  border-radius: 11px;
  font-size: 11px;
  font-weight: $weight__bold;
  text-align: center;
  color: #fff;
  line-height: 22px;
  &--DDI {
    background-color: $color__employees--ddi;
  }
  &--DO {
    background-color: $color__employees--advisers;
  }
  &--KS {
    background-color: $color__employees--accounting;
  }
  &--ADM {
    background-color: $color__employees--administration;
  }
  &--MA {
    background-color: $color__employees--marketing;
  }
  &--AD {
    background-color: $color__employees--admin;
  }
  &--DIR {
    background-color: $color__employees--dir;
  }
  &--DP {
    background-color: $color__employees--dp;
  }
  &--Partner {
    background-color: #8f94ac;
  }
  &--Inwst,
  &--INWST {
    background-color: $color__investors;
  }
  &--in {
    background-color: #ff9100;
    color: #fff;
    font-weight: $weight__bold;
  }
  &--out {
    background-color: #ff3333;
    color: #fff;
    font-weight: $weight__bold;
  }
  &--prospect {
    background-color: #15b791;
    color: #fff;
    font-weight: $weight__bold;
  }
  &--mobile {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    padding: 0;
    position: relative;
    &.badge {
      &--in {
        background-color: rgba($color__status--in, 0.15);
        .badge--innerShadow {
          background-color: #15b791;
        }
      }
      &--out {
        background-color: rgba($color__status--out, 0.15);
        .badge--innerShadow {
          background-color: #ff3333;
        }
      }
      &--prospect {
        background-color: rgba($color__status--prospect, 0.15);
        .badge--innerShadow {
          background-color: #ff9100;
        }
      }
    }
  }
  &--innerShadow {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    padding: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
