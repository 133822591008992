.reservationSurname {
  display: flex;
  align-items: center;
  &--dot {
    border-radius: 8px;
    display: block;
    height: 8px;
    margin: 0 6px 0 8px;
    width: 8px;
    &--ddi {
      background-color: rgba(200, 208, 247, 1);
    }
    &--inwst {
      background-color: #ff9100;
    }
  }
  &--name {
    font-size: 11px;
    text-transform: uppercase;
  }
  &--surname {
    font-size: 11px;
    text-transform: capitalize;
    margin-left: 3px;
  }
  &--name {
    display: block;
    overflow: hidden;
    white-space: nowrap;
  }
}
