.filters {
  background-color: rgba(0, 0, 0, 0.26);
  backdrop-filter: blur(1px);
  display: flex;
  left: 0;
  min-height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 998;
  &__inner {
    width: 376px;
    overflow: unset;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    right: 0;
    box-shadow: -9px 0px 38px -20px rgba(0, 0, 0, 0.4);
    overflow-y: scroll;
    @media (max-width: 756px) {
      width: 100%;
    }
  }
  &__content {
    background-color: #fff;
    padding: 22px 21px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    @media (max-width: 756px) {
      padding: 35px 20px;
      border-radius: 0;
    }
  }
  &__head {
    display: flex;
    justify-content: flex-end;
  }
  &__search {
    position: relative;
    width: calc(100% - 55px);
    &--input {
      background-color: rgba(175, 175, 175, 0.1);
      border: unset;
      border-radius: 15px;
      padding: 9px 16px 9px 40px;
      width: 100%;
    }
    &--loupe {
      position: absolute;
      left: 6px;
      top: 6px;
    }
  }
  &__filter-icon {
    cursor: pointer;
  }

  &__form {
    padding-left: 5px;
    .select-field__label {
      font-size: 14px;
      font-weight: 700;
      margin-top: 20px;
      &--bottom {
        margin-bottom: 20px;
      }
      &--small {
        color: rgba(0, 0, 0, 0.56);
        font-size: 12px;
        font-weight: 500;
      }
    }
    .select-field {
      border-bottom: 1px solid #e1e1e1;
    }
    &--border {
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 5px;
      margin-bottom: 20px;
    }
    &--half {
      align-items: center;
      display: flex;
      margin-bottom: 7px;
      &--border {
        border-bottom: 1px solid #e1e1e1;
      }
    }
    .regular-field__container {
      margin: 5px 0;
      width: 100%;
    }
    .regular-field__input input {
      font-size: 12px;
    }
  }

  &__tile {
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.03);
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 39px;
    position: relative;

    &--label {
      font-size: 12px;
      padding-left: 7px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: calc(100% - 50px);
      white-space: nowrap;
      z-index: 1;

      &--customLabel {
        color: black;
        opacity: 0.5;
      }
    }
    &--point {
      border-radius: 50%;
      display: block;
      height: 6px;
      width: 6px;
      margin-left: 7px;
      z-index: 1;

      &--green {
        background-color: $color__primary--green;
      }
      &--yellow {
        background-color: $color__primary--yellow;
      }
      &--red {
        background-color: $color__primary--red;
      }
      &--orange {
        background-color: $color__primary--orange;
      }
      &--black {
        background-color: #000;
      }
    }
    &--icon {
      margin-left: 7px;
    }
  }
  &__group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 6px;
    column-gap: 14px;
  }
  &--pack {
    margin-top: 20px;
    span {
      margin-bottom: 10px;
      display: block;
    }
  }
  &__button {
    display: flex;
    justify-content: center;
    margin-top: 26px;
    width: 100%;
    .btn {
      width: 190px;
    }
  }
  &--outside {
    width: calc(100% - 375px);
    height: 100vh;
  }
  &--checkbox {
    opacity: 0;
    position: absolute;
  }
  &--checkbox--label {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &--checkbox,
  &--checkbox--label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
  }
  &--checkbox--label {
    background-color: rgba(172, 172, 172, 0.06);
    margin-top: -1px;
    margin-left: -1px;
    border-radius: 5px;
    z-index: 2;
  }
  &--checkbox + &--checkbox--label:before {
    content: '';
    padding: 1px;
    position: absolute;
  }
  &--checkbox:checked + &--checkbox--label {
    background-color: transparent;
  }
  &--checkbox:checked + &--checkbox--label:after {
    background-color: #15b791;
    border-radius: 15px;
    content: '';
    padding: 2px;
    position: absolute;
    right: 4px;
    top: 12px;
    height: 14px;
    width: 14px;
  }
  &--checkbox:checked + &--checkbox--label:before {
    border-radius: 0px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    content: '';
    display: inline-block;
    height: 4px;
    margin: 0px 15px 5px 5px;
    top: 16px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    right: -5px;
    width: 1px;
    z-index: 9;
  }
}
