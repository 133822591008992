.active-window {
  &__wrapper {
    width: 100%;
    height: 100%;
    background-color: #000;
    position: fixed;
    overflow-y: auto;
    font-size: 12px;
    z-index: 2;
    @include md {
      color: #fff;
      box-shadow: -9px 0px 38px -20px rgba(0, 0, 0, 0.1);
      background-color: unset;
      position: unset;
    }
  }

  &__header {
    height: 140px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
    padding: 26px 23px 0 23px;
    color: #000;
    min-height: calc(100% - 73px);

    .rt-tbody {
      padding: 6px;
      .rt-tr-group {
        background-color: #fff;
        filter: drop-shadow(-15px 0px 11px #0000000a);
        .rt-tr {
          padding: 10px;
        }
      }
    }
  }
  &__submenu {
    height: 50px;
    padding: 3px 9px;
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
  &__submenu-item {
    font-size: 14px;
    font-weight: $weight__regular;
    color: rgba(180, 180, 180, 0.54);
    cursor: pointer;
    padding: 0 22px;
    &--active {
      border-radius: 15px;
      background-color: rgba(255, 255, 255, 0.09);
      font-weight: $weight__bold;
      color: rgba(255, 255, 255, 1);
      padding: 5px 20px;
    }
  }
  &__data {
    color: gray;
  }
  &__group-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 118px;
    @include md {
      padding-bottom: 18px;
    }
  }
  &__group-label {
    color: rgba(99, 99, 99, 1);
    font-size: 14px;
    font-weight: $weight__regular;
    padding-bottom: 13px;
  }
  &__select {
    background-color: rgba(241, 241, 241, 0.51);
    border-radius: 8px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 12px;
    font-size: 12px;
    font-weight: $weight__regular;
    font-family: $font__poppins;
    position: relative;
    .badge {
      margin-right: 10px;
    }
  }
  &__container {
    display: flex;
    justify-content: flex-start;
    .active-window__field-wrapper {
      &:first-of-type {
        width: 50%;
      }
    }
  }
  &__field-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    padding-bottom: 20px;
    &.active {
      color: #15b791;
      .active-window {
        &__select,
        &__input {
          font-weight: $weight__bold;
        }
      }
    }
  }
  &__field-label {
    font-size: 11px;
    font-weight: $weight__regular;
    color: rgba(0, 0, 0, 0.41);
    padding-bottom: 4px;
  }
  &__input {
    font-size: 12px;
    font-weight: $weight__regular;
    color: rgba(17, 17, 17, 0.97);
    &--phone {
      color: rgba(17, 17, 17, 0.22);
      margin-right: 13px;
    }
  }
  &__row-align {
    display: flex;
    justify-content: space-between;
    .active-window__field-wrapper {
      flex: 0 0 46%;
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }
  &__content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    &--title {
      font-size: 15px;
      font-weight: $weight__medium;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  &__delete {
    width: 30px;
    position: absolute;
    height: 30px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .svg {
      width: 10px;
      height: 10px;
    }
  }
  &__files {
    border-radius: 5px;
    box-shadow: -15px 0px 11px rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 20px;
    position: relative;
    svg {
      height: 20px;
    }
    .svg--exit {
      width: 15px;
      cursor: pointer;
    }
    a {
      color: transparent;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    span {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.98);
      font-size: 10px;
      margin-left: 10px;
    }
    &--container {
      display: flex;
      align-items: center;
    }
  }
}
