.login {
  &__form {
    max-width: 266px;
    margin: 0 auto;
  }
  &__actions {
    display: none;
    justify-content: space-between;
    align-items: center;
  }
  &__reset-password {
    font-size: 11px;
    font-weight: $weight__regular;
    color: rgba(#000, 0.19);
    cursor: pointer;
    text-decoration: none;
  }
  &__error {
    font-size: 11px;
    font-weight: $weight__regular;
    color: $color__primary--red;
  }
  &--shadowed {
    opacity: 0.4;
  }
}
