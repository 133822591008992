.addOfferModal {
  .regular-field__container {
    margin: 0;
  }
  &__header {
    background-color: rgba(172, 172, 172, 0.06);
    border-radius: 8px;
    padding: 6px 4px 0 4px;
    width: 100%;
    margin-bottom: 15px;
    &--date {
      padding-top: 20px;
    }
    &--top,
    &--bottom {
      display: flex;
      align-items: center;
    }
    &--bottom {
      margin-top: 20px;
      .media__list--item {
        margin: 0 5px;
      }
    }
    &--input120 {
      max-width: 120px;
      margin-left: 30px;
    }
    &--input {
      border: 0;
      background-color: rgba(172, 172, 172, 0.06);
      input {
        background-color: transparent;
        text-align: right;
      }
    }
    &--percentage {
      max-width: 72px;
      margin-left: 10px;
      &:after {
        content: '%';
        color: rgba(42, 42, 42, 1);
        position: absolute;
        // font-weight: $weight__regular;
        // font-size: 14px;
        // top: 50%;
        // transform: translateY(-50%);
        right: 13px;
        // width: 5px;
        // line-height: 39px;
        // height: 40px;
      }
      input {
        text-align: center;
      }
    }
    &--percentage--green {
      margin-left: 10px;
      position: relative;
      padding: 0 15px;
      input {
        text-align: center;
        color: rgba(21, 183, 145, 1);
      }
      &:after {
        content: '%';
        color: rgba(21, 183, 145, 1);
        position: unset;
        // font-weight: $weight__regular;
        // font-size: 14px;
        // top: 50%;
        // transform: translateY(-50%);
        // left: 14px;
        // width: 5px;
        // line-height: 39px;
        // height: 40px;
      }
      &:before {
        content: '-';
        color: rgba(21, 183, 145, 1);
        // position: unset;
        font-weight: $weight__regular;
        font-size: 14px;
        // top: 50%;
        // transform: translateY(-50%);
        // right: 17px;
        // width: 5px;
        // line-height: 39px;
        // height: 40px;
      }
    }
    &--comment {
      margin-top: 100px;
      border-radius: 12px;
      width: 100%;
      .regular-field__container {
        width: 100%;
        max-width: 100%;
        height: 100%;
        overflow-wrap: break-word;
      }
    }
  }
  &--icon {
    height: 16px;
  }
  &--number {
    color: rgba(0, 0, 0, 0.12);
    font-weight: 700;
    font-size: 10px;
    margin: 0 7px;
  }
  &--plotName {
    color: rgba(3, 3, 3, 0.57);
    font-size: 10px;
    font-weight: 700;
  }
  &__plot-number {
    align-items: center;
    background-color: rgba(48, 48, 48, 0.03);
    border-radius: 8px;
    color: rgba(43, 43, 43, 0.78);
    display: flex;
    font-size: 11px;
    margin-right: 5px;
    padding: 4px 8px;
    position: relative;
  }
  &--dot {
    background-color: rgba(21, 183, 145, 1);
    display: block;
    border-radius: 5px;
    height: 5px;
    width: 5px;
    position: absolute;
    left: -2px;
  }
  &__yardage {
    font-size: 11px;
    color: rgba(0, 0, 0, 0.48);
    margin-left: 5px;
    display: flex;
    align-items: center;
    &--left {
      margin-left: auto;
    }
    &--dot {
      background-color: rgba(90, 90, 90, 0.48);
      display: block;
      border-radius: 5px;
      height: 5px;
      width: 5px;
      margin-right: 5px;
    }
  }
  textarea {
    width: 100% !important;
    border: 0;
    height: 100px !important;
    resize: none;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.89);
    line-height: 16px;
    border-radius: 14px;
    padding: 5px 0;
    &:focus-visible {
      border: black;
      outline: none;
    }
  }
  &__price {
    position: relative;
  }
  &__oldPrice {
    text-decoration: line-through;
    font-size: 12px;
    color: #d4d4d4;
    position: absolute;
    top: -10px;
    right: 5px;
  }
}
