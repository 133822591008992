.form__counter {
  opacity: 21%;
  font-size: 10px;
}

* {
  margin: 0;
  padding: 0;
}

.select-field {
  padding: 10px 0 5px 0;
}

.filtersInfo {
  font-size: 12px;
  padding: 10px;
  opacity: 0.5;
}
