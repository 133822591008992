.settlement-plots,
.allPlots,
.plot {
  width: 100%;
  &.employees-right {
    .plotList {
      color: #000;
    }
    .notificationsRight__list {
      padding-bottom: unset;
    }
  }
  .ReactTable {
    font-size: 11px;
    line-height: 12px;
    margin-bottom: 17px;
    .rt-tbody {
      padding: 6px;
    }
    .rt-tr-group {
      // box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
      background-color: #fff;
    }
    .rt-tr {
      position: relative;
    }
    .rt-noData {
      padding: 0;
    }
  }
  .media__list {
    svg {
      margin-right: 10px;
    }
    &--item {
      &.disabled {
        opacity: 0.11;
      }
    }
  }
  &__content {
    padding-right: 0px;
    padding-left: 0px;
    min-height: calc(100% - 300px);
    height: auto;
  }

  .employees-right__header {
    min-height: 300px;
    flex-direction: column;
    display: flex;
    // @media (max-width: 756px) {
    //   display: none !important;
    // }
    .settlementName {
      margin-top: auto;
    }
  }
  &--icon {
    padding: 23px;
    margin-left: auto;
  }
  &--icons {
    div {
      cursor: auto;
      padding: 48px 23px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    svg {
      cursor: pointer;
    }
  }
}
.settlementFiles {
  width: 100%;
  height: auto;
  overflow: auto;
  padding: 0 20px;

  .padding {
    padding-top: 20px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;

    .files {
      &__counter {
        color: #6e6e6e;
        font-weight: bold;
        margin-right: 5px;
      }

      &__title {
        color: rgba(180, 180, 180, 0.54);
        margin-left: 5px;
      }
    }
  }
  .content {
  }
}

.settlement-plots,
.allPlots {
  .rt-tr-group {
    min-height: 52px;
    filter: drop-shadow(-15px 0px 11px #0000000a);
    border-radius: 5px;
    .rt-td:nth-child(3) {
      color: rgba(0, 0, 0, 0.48);
      position: relative;
      display: flex;
      align-items: center;
      &:after {
        content: '';
        width: 5px;
        height: 5px;
        display: block;
        position: absolute;
        background-color: rgba(90, 90, 90, 0.48);
        left: 1px;
        border-radius: 5px;
      }
    }
    .rt-td:nth-child(4):empty {
      background-color: red;
      display: none;
    }
  }
  .ReactTable .rt-tbody .rt-td {
    border-right: unset;
  }
}

.table-header {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 12px;
  &__title {
    color: #000;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 500;
    margin-left: 7px;
    display: inline-block;
  }
  &__count {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: inline-block;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    line-height: 20px;
    color: #000;
  }
  &--orange {
    .table-header {
      &__count {
        background-color: #ffe5c2;
      }
    }
  }
  &--green {
    .table-header {
      &__count {
        color: #fff;
        background-color: #15b791;
        border-radius: 3px;
      }
    }
  }
}
.allPlots {
  .pagination-bottom {
    margin-bottom: 50px;
  }
  // &__plotName {
  //   background-color: rgba(48, 48, 48, 0.03);
  //   padding: 5px 7px;
  //   border-radius: 8px;
  //   margin-left: 5px;
  //   position: relative;
  //   display: flex;
  //   align-items: center;
  //   &:after {
  //     border-radius: 13px;
  //     content: '';
  //     display: block;
  //     left: -2px;
  //     position: absolute;
  //     height: 5px;
  //     width: 5px;
  //   }
  //   &--green:after {
  //     background-color: #15b791;
  //   }
  //   &--black:after {
  //     background-color: #000000;
  //   }
  // }
  .ReactTable {
    color: #000;
  }
  @media (max-width: 756px) {
    margin-top: -20px;
    padding-top: 100px;
  }

  .employees-right__header {
    min-height: 0;
    height: auto;
  }
  &__content {
    padding-right: 0px;
    padding-left: 0px;
    min-height: calc(100% - 73px);
    height: auto;
  }
  &__header.employees-right__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 19px;
  }
  &__icon {
    padding: 0;
  }
  &__plotNumberInput {
    flex: 1 0 250px;
    padding-right: 25px;
    input {
      width: 100%;
      padding: 7px 10px;
      background-color: rgba(255, 255, 255, 0.1);
      border: 0px;
      border-radius: 15px;
      font-family: 'Poppins', sans-serif;
    }
  }
}
.svg-square-wrapper {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  &--white {
    background-color: rgba(255, 255, 255, 0.3);
  }
}

.settlementName,
.plotName {
  display: flex;
  align-items: center;
  padding: 3px 22px;

  &__name {
    // margin-left: 15px;
    @media (max-width: 756px) {
      color: rgba(217, 217, 217, 1);
      font-weight: 700;
    }
  }
  &__icon {
    &--map {
      margin-left: auto;
    }
    &--filters {
      margin-left: auto;
    }
    &--home {
      margin-right: 15px;
    }
    &--back {
      width: 24px;
      height: 24px;
      background-color: rgba(255, 255, 255, 0.37);
      border-radius: 4px;
      margin-right: 18px;
      margin-bottom: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}
.plotName {
  border-bottom: 2px solid rgba(212, 212, 212, 0.06);
  padding: 0 0 4px 0;
  margin: 3px 15px 3px 22px;
  margin-top: auto;
  &__name {
    color: rgba(217, 217, 217, 1);
    font-size: 29px;
    font-weight: 700;
    margin-right: 20px;
  }
  &__subName {
    font-size: 16px;
    color: rgba(217, 217, 217, 0.64);
    font-weight: 700;
  }
  &__icon--back {
    margin-right: 10px;
    flex-shrink: 0;
  }
}
.dashboard-routes__left .employees-right .employees-right__header {
  @media (max-width: 756px) {
    display: none !important;
  }
}

.plot {
  &__singleElementBar {
    width: calc(100% - 110px);
    height: 50px;
    position: absolute;
    top: -2px;
    left: 0;
    cursor: pointer;
    z-index: 9;
    &--fullWidth {
      width: 100%;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
    padding: 0 23px 0 23px;
    height: 100%;
    padding-right: 20px;
    padding-left: 20px;
    height: auto;
    min-height: calc(100% - 300px);

    @media (max-width: 756px) {
      min-height: calc(100% - 140px);
      padding: 0 20px;
      overflow: auto;
    }
    .statusButtons {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      padding: 0 5px;
      &__establishAct {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        .item {
          padding: 10px 30px;
        }
      }
    }
    .addOffer {
      border-top: 1px solid rgba(243, 243, 243, 1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: rgba(99, 99, 99, 1);
      font-size: 14px;
      margin-top: 30px;
      padding: 15px 5px 30px 5px;
      .btn {
        font-size: 11px;
      }
    }
    .active-window__submenu {
      padding: 0;
      background-color: rgba(161, 161, 161, 0.13);
      height: auto;
      border-radius: 6px;
      &-item {
        font-size: 12px;
        border-radius: 6px;
        &--active {
          color: rgba(17, 17, 17, 0.97);
          font-weight: 400;
          filter: drop-shadow(2px 4px 11px #aaaaaaa1);
          background-color: #fff;
        }
      }
    }
  }
  &__header {
    .plotName__icon--back {
      margin-bottom: 0;
    }
    @media (min-width: 756px) {
      min-height: 300px;
      flex-direction: column;
      display: flex;
    }
  }
  &--icons--map {
    margin-left: auto;
    margin-right: 35px;
  }
}

.statusBar {
  display: flex;
  &__statusInfo {
    display: flex;
    margin-left: auto;
    font-size: 12px;
    font-weight: 700;
    margin-top: 30px;
    &--const {
      color: rgba(0, 0, 0, 0.23);
    }
    &--black {
      margin-left: 5px;
      color: rgba(0, 0, 0, 1);
    }
    &--green {
      color: rgba(21, 183, 145, 1);
      margin-left: 5px;
    }
    &--red {
      color: #ff3333;
      margin-left: 5px;
    }
    &--orange {
      color: rgba(255, 145, 0, 1);
      margin-left: 5px;
    }
  }
  &__data {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.38);
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    padding-left: 5px;
  }
}

.HorizontalStatusBar {
  margin-top: 30px;
  &__content {
    display: flex;
  }
  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 24%;

    &:last-child {
      width: 6%;
    }
    &:last-child::after {
      content: '';
      border: 2.5px solid rgba(243, 243, 243, 1);
      top: 7px;
      left: 17px;
      position: absolute;
    }
    &:not(:last-child):after {
      content: '';
      border: 2.5px solid rgba(243, 243, 243, 1);
      position: absolute;
      width: calc(100% - 15.5px);
      top: 7px;
      left: 17px;
    }
    &--gray:not(:last-child):after {
      border: 2.5px solid rgba(114, 114, 114, 1);
    }
    &--orange:not(:last-child):after {
      border: 2.5px solid rgba(255, 145, 0, 1);
    }
    &--red:not(:last-child):after {
      border: 2.5px solid rgba(255, 51, 51, 1);
    }
    &--black:last-child:after {
      border: 0;
    }
    &--black:not(:last-child):after {
      border: 2.5px solid rgba(36, 36, 36, 1);
    }
  }
  &__dot {
    display: inline-block;
    width: 13px;
    height: 13px;
    border: 1px solid rgba(230, 230, 230, 1);
    border-radius: 50%;
    margin-left: 4px;
    margin-top: 2px;
    z-index: 9;
    &--gray {
      background-color: rgba(114, 114, 114, 1);
      border: 0;
    }
    &--orange {
      background-color: rgba(255, 145, 0, 1);
      border: 0;
    }
    &--red {
      background-color: rgba(255, 51, 51, 1);
      border: 0;
    }
    &--black {
      background-color: rgba(36, 36, 36, 1);
      border: 0;
    }
    &--current {
      position: relative;
      &--green:before {
        background-color: rgba(21, 183, 145, 1);
      }
      &--orange:before {
        background-color: rgba(255, 145, 0, 1);
      }
      &--black:before {
        background-color: rgba(36, 36, 36, 1);
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 22px;
        height: 22px;
        top: -5.6px;
        left: -5.6px;
        border-radius: 15px;
        background-color: #fff;
        filter: drop-shadow(0px 4px 8px #aaaaaaa1);
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 13px;
        height: 13px;
        border-radius: 15px;
        z-index: 9;
        left: -1px;
        top: -1px;
      }
    }
  }
}

.offerBar {
  align-items: center;
  background-color: rgba(172, 172, 172, 0.06);
  border-radius: 8px;
  display: flex;
  font-size: 15px;
  padding: 11px 7px;
  margin: 4px 0;
  width: 100%;
  &__wrapper {
    margin-top: 11px;

    &--preBooking {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
  &__item {
    display: flex;
    width: 100%;
    align-items: center;
  }
  p:not(:last-child) {
    margin: 0 5px;
  }
  &__price {
    color: rgba(0, 0, 0, 0.34);
  }
  &__discount {
    color: rgba(21, 183, 145, 1);
  }
  &__data {
    color: rgba(0, 0, 0, 0.34);
    margin-left: 20px;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    min-width: 200px;
  }
  &__cancel {
    color: rgba(200, 200, 200, 0.72);
    font-size: 11px;
    margin-left: auto;
    margin-right: 5px;
    cursor: pointer;
  }
  &--accept {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    background-color: rgba(172, 172, 172, 0.06);
    border-radius: 8px;
    height: 44.5px;
    margin-left: 10px;
    cursor: pointer;
  }
}

.preBooking {
  margin-top: 40px;
  &--paragraph {
    color: rgba(0, 0, 0, 0.57);
    font-size: 11px;
    line-height: 17px;
    margin-top: 20px;
    padding: 0 20px;
  }
  &--file {
    color: rgba(0, 0, 0, 0.98);
    cursor: pointer;
  }
  &--files {
    margin-top: 25px;
  }
  &__actions {
    align-items: center;
    border-bottom: 1px solid rgba(243, 243, 243, 1);
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    padding-bottom: 5px;
    &--paragraph {
      color: rgba(99, 99, 99, 1);
      font-size: 14px;
      line-height: 21px;
      padding-left: 10px;
    }
  }
  &__customDate {
    margin-top: 20px;
  }
}

.signedContract {
  // margin-top: auto;
  // margin-bottom: 40px;
  // padding-top: 27px;

  &__inputComparison {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 50%;
  }

  &__text {
    color: #737373;
    margin-bottom: 20px;
  }

  &__investor {
    margin-top: 27px;
  }
  &__files {
    border-top: 1px solid #f3f3f3;
    margin-top: auto;
    margin-bottom: 40px;
    padding-top: 12px;
    .form_field--fileinput_singleFile {
      margin-left: 4px;
      cursor: pointer;
      width: 100%;
    }
    &--top {
      margin-top: 10px;
    }
  }
  &__file {
    border-top: 1px solid #f3f3f3;
    margin-top: 15px;
    padding-top: 15px;
  }
  &__appointment {
    padding-top: 20px;
    // margin-top: 10px;
    margin: 10px -20px 0 -22px;
    border-top: 1px solid #f3f3f3;
    .notificationsRight {
      &__hour {
        color: rgba(0, 0, 0, 1);
      }
      &__user {
        color: rgba(0, 0, 0, 0.72);
      }
    }
  }
}

.settlement-plots {
  .pagination-bottom {
    margin-bottom: 0;
  }
}
