.code-verification {
  &__form {
    display: flex;
    justify-content: center;
    margin-top: 8px;
  }
  &__wrapper {
    width: unset !important;
    input {
      border: 1px solid $color__input-border !important;
      margin: 9px;
      border-radius: 6px !important;
      font-family: $font__poppins !important;
      font-size: 18px !important;
      font-weight: $weight__bold !important;
      color: #000 !important;
      caret-color: #000 !important;
    }
  }
  &__description {
    font-size: 10px;
    font-weight: $weight__regular;
    color: rgba(#000, 0.42);
    margin: 10px 0 45px;
  }
  &__error {
    font-size: 11px;
    font-weight: $weight__regular;
    color: $color__primary--red;
  }
  &--shadowed {
    opacity: 0.4;
  }
}
