@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.button {
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  box-shadow: border-box;
  width: 70px;
  height: 59px;
  background: none;
  border-radius: 5px;
  border: 1px solid #70707030;
  margin: 5px;
  position: relative;
  cursor: pointer;
}

.btnWrapper {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.smallerSvg {
  margin-right: 10px;
}

.counter {
  width: 17px;
  height: 17px;
  position: absolute;
  top: -7px;
  left: 55px;
  border-radius: 50%;
  background-color: #ff9100;
  color: white;
}

.counter_grey {
  background-color: #d9d9d9;
}

.iconWrapper {
  margin: 5px 0 5px 0;
}

.vertical {
  margin-right: 10px;
}

.range {
  width: 85%;
  margin: auto;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 3px solid #000000;
  border-radius: 2px;
}

.ranges-wrapper {
  margin-top: 2px;
  margin-bottom: 2px;
}
