.single-notification-right {
  &__hour {
    font-size: 23px;
    font-weight: $weight__medium;
    padding: 0 13px;
  }

  &__toDo {
    font-size: 1px;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid rgba(112, 112, 112, 0.5);
    margin-right: 10px;
  }

  &__done {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
  }

  &__content {
    // display: flex;
    margin-top: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 15px 20px;
    box-shadow: 0px -6px 9px #00000012;
  }
  &__title {
    font-size: 15px;
    font-weight: $weight__regular;
    padding: 18px 12px;
  }
  &__description {
    // margin-top: 13px;
    padding: 0 12px;
    font-size: 13px;
    font-weight: $weight__regular;
    color: #6e6e6e;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-content: baseline;
    margin-bottom: 10px;
    .employees-right__phone {
      width: 40px;
      height: 40px;
      // margin-left: 20px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .employees-right__select {
    width: 80%;
    margin-top: 2px;
    cursor: pointer;

    a {
      text-decoration: none;
    }

    .locationWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .location {
        width: 70px;
        height: 30px;
        border-radius: 10%;
        background-color: rgba(0, 74, 158, 0.1);
        text-align: center;
        color: rgba(0, 74, 158, 1);
        font-size: 11px;
        padding-top: 7px;
        font-weight: 400;
      }
    }

    &-investor {
      margin: 0 0 0 10px;
    }

    &-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .settlement {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-name {
          margin-left: 10px;
        }
      }
    }
  }

  .details {
    width: 100%;
  }
}
