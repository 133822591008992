.modal {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.26);

  &--sort {
    .react-select__menu {
      max-height: 200px;
      div {
        max-height: 200px;
      }
    }
  }

  &__header {
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(243, 243, 243, 1);
    &--multistep {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 8px;
    }
    .btn .svg {
      transform: rotate(-90deg);
      height: 7px;
    }
  }
  &__title {
    font-size: 14px;
    font-family: $font__poppins;
    font-weight: $weight__bold;
    color: rgba(91, 91, 91, 1);
  }
  // &__content {
  //   // padding-top: 20px;
  // }
  ///////////////
  &__inner {
    overflow: auto;
    padding: 15px 20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 100%;
    height: 100%;

    @include md {
      max-height: 100%;
      border-radius: 8px;
      box-shadow: 0px 3px 40px #00000029;
      padding: 37px 21px;
      min-width: 374px;
      width: unset;
      height: unset;
    }
    &--map {
      padding: 0;
      // width: calc(100% - 150px);
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    .btn--transparent {
      color: rgba(180, 180, 180, 1);
    }
  }

  &--delete {
    @media (max-width: 755px) {
      .modal__inner {
        padding: 35px 20px;
        min-height: 375px;
        height: unset;
        transform: translate(-50%, 0);
        bottom: 0;
        border-top-left-radius: 19px;
        border-top-right-radius: 19px;
      }
    }
  }

  &--show {
    @media (max-width: 755px) {
      .modal__inner {
        padding: 35px 20px;
        min-height: 375px;
        height: unset;
        transform: translate(-50%, 0);
        bottom: 0;
        border-top-left-radius: 19px;
        border-top-right-radius: 19px;
      }
    }
  }

  &--reservation {
    @media (max-width: 755px) {
      .modal__inner {
        padding: 35px 20px;
        // min-height: 375px;
        height: unset;
        transform: translate(-50%, 0);
        top: auto;
        height: auto;
        min-height: unset;
        bottom: 0;
        border-top-left-radius: 19px;
        border-top-right-radius: 19px;
      }
    }
  }

  &--manage {
    backdrop-filter: unset;
    background-color: rgba(0, 0, 0, 0.05);
    .modal__inner {
      position: absolute;
      left: 86%;
      top: 360px;
      width: unset;
      height: unset;
      // min-height: 362px;
      min-width: 374px;
      .modal__header {
        padding-bottom: 5px;
        border-bottom: 0;
      }

      .modal__content {
        padding-top: 0;

        // .modal__actions {
        //   margin-top: 80px;
        // }
      }
    }
  }

  &--range {
    backdrop-filter: unset;
    background-color: rgba(0, 0, 0, 0.05);

    &__header {
      border-bottom: 1px solid rgba(243, 243, 243, 1);
    }

    .modal__inner {
      position: absolute;
      left: 80%;
      top: 430px;
      width: unset;
      height: unset;
      min-height: 362px;
      min-width: 374px;
      .modal__header {
        padding-bottom: 5px;
        border-bottom: 0;
      }

      .modal__content {
        padding-top: 0;

        .modal__actions {
          margin-top: 10px;
        }
      }
    }
  }

  &--sort {
    backdrop-filter: unset;
    background-color: rgba(0, 0, 0, 0.05);

    .modal__inner {
      position: absolute;
      left: 86%;
      top: 380px;
      width: unset;
      height: unset;
      min-height: 362px;
      min-width: 374px;

      .modal__header {
        padding-bottom: 5px;
        border-bottom: 0;
      }

      .modal__content {
        padding-top: 0;

        .tabs-toggle__options {
          display: flex;
          flex-direction: column;

          .tabs-toggle__option {
            border-radius: 10px;

            span {
              font-size: 12px;
              color: rgba(0, 0, 0, 1);
            }
          }
        }

        .modal__actions {
          margin-bottom: 20px;
        }
      }
    }
  }

  &--group {
    backdrop-filter: unset;
    background-color: rgba(0, 0, 0, 0.05);

    .modal__inner {
      position: absolute;
      left: 86%;
      top: 360px;
      width: unset;
      height: unset;
      min-height: 362px;
      min-width: 374px;

      .modal__header {
        padding-bottom: 5px;
        border-bottom: 0;

        .modal__title {
          margin-bottom: 20px;
        }
      }

      .modal__content {
        padding-top: 0;

        .modal__actions {
          margin-top: 50px;
        }
      }
    }
  }

  &__form {
    .form_field--fileinput {
      margin-top: 15px;
    }
    &--section-name {
      color: #636363;
      font-size: 14px;
      line-height: 21px;
      padding-left: 5px;
      margin: 10px 0;
    }
    .single-file {
      margin-top: 15px;
      display: flex;
      align-items: center;
      box-shadow: 2px 4px 11px #00000014;
      padding: 12px 16px;
      font-size: 10px;
      font-weight: $weight__regular;
      justify-content: space-between;
      .svg--file {
        width: 13px;
        height: 14px;
        margin-right: 16px;
      }
      &__left {
        display: flex;
      }
      &__right {
        cursor: pointer;
      }
    }
    .submenu {
      display: flex;
      justify-content: flex-end;
      &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &__item {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 3px;
        &--contact {
          background-color: rgba(21, 183, 145, 0.3);
          border-radius: 8px;
        }
        &--meeting {
          background-color: rgba(0, 74, 158, 0.3);
          border-radius: 8px;
        }
        &--note {
          background-color: rgba(255, 207, 51, 0.3);
          border-radius: 8px;
        }
      }
    }
  }
  &__addDeposit {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &:nth-child(3) {
      margin-bottom: 20px;
    }

    &--error {
      font-size: 10px;
      position: absolute;
      top: 200px;
      left: 20px;
      color: $color__primary--red;
    }

    .inputComparison__wrapper {
      display: flex;
      align-items: center;
      cursor: auto;
      width: 50%;
    }
    &--info {
      display: flex;
      align-items: center;
      p {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.54);
        margin-right: 10px;
        padding-left: 5px;
      }
      &-first {
        width: 40%;
      }
      &-second {
        width: 50%;
        display: flex;
        justify-content: flex-start;
      }
    }
    .regular-field {
      width: 40%;
      &__label {
        background-color: transparent;
        left: 0;
        &--error {
          top: 45px;
          left: -3px;
        }
      }
      &__input {
        background-color: rgba(172, 172, 172, 0.06);
        border: unset;
      }
      input {
        background-color: transparent;
        text-align: center;
      }
    }
  }
}

.cancel_reservation {
  margin: 20px 10px;
}

@media (max-width: 755px) {
  .modal__transition-enter {
    .modal__inner {
      transform: translateY(450px);
      left: 0;
      right: 0;
    }
  }

  .modal__transition-enter-active {
    .modal__inner {
      transform: translateY(0);
      transition: transform 300ms;
    }
  }

  .modal__transition-exit {
    .modal__inner {
      transform: translateY(0);
    }
  }

  .modal__transition-exit-active {
    .modal__inner {
      transform: translateY(450px);
      transition: transform 300ms;
      left: 0;
      right: 0;
    }
  }
}

.form_investor {
  max-width: 375px;
  @media (min-width: 768px) {
    width: 375px;
  }
  &__top-fields {
    display: flex;
    align-items: flex-end;
    margin: 20px 0;
    .select-field__wrapper,
    .regular-field {
      width: 50%;
    }
    .select-field__wrapper {
      margin-right: 5px;
    }
    .regular-field {
      margin-left: 15px;
      &__container {
        // margin: 18px 0;
        margin: 0 0 3px;
      }
      &__input--phone {
        width: 100%;
      }
    }
    .select-field__error-label {
      position: absolute;
    }
    .select-field__error-label {
      position: absolute;
      bottom: -20px;
    }
    .select-field__input--error {
      margin-bottom: 3px;
    }
    .select-field__container {
      position: relative;
    }
  }

  &__adviser-field {
    display: flex;
    justify-content: center;
    margin: 20px 0;

    .select-field__wrapper,
    .regular-field {
      width: 100%;
    }
    .select-field__wrapper {
      margin-right: 5px;
    }
    .regular-field {
      margin-left: 15px;
      &__container {
        // margin: 18px 0;
        margin: 0 0 3px;
      }
      &__input--phone {
        width: 100%;
      }
    }
    .select-field__error-label {
      position: absolute;
    }
    .select-field__error-label {
      position: absolute;
      bottom: -20px;
    }
    .select-field__input--error {
      margin-bottom: 3px;
    }
    .select-field__container {
      position: relative;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.address_field-halfs {
  display: flex;
  margin: 25px 0;
  .regular-field {
    flex: 1 0;
    &:first-of-type {
      margin-right: 5px;
    }
    &:nth-of-type(2) {
      margin-left: 5px;
    }
    &__container {
      margin: 0;
    }
  }
}

.appointmentNote {
  &__calendary {
    display: flex;
    align-items: center;
    .time_picker_container {
      width: 95px;
      padding-top: 15px;
    }
    .regular-field__container {
      margin: 0;
    }
    .submenu {
      margin-left: auto;
      &__item {
        cursor: auto;
      }
    }
  }
  &__plotInfo {
    margin-top: 0 !important;
    background-color: rgba(241, 241, 241, 0.51);
    border-radius: 8px;
    padding: 9px;
  }
  height: 100%;
}
