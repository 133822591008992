.settings {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  backdrop-filter: blur(1px);
  background-color: rgba(0, 0, 0, 0.26);
  &__inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #000;
    height: 100vh;
    position: fixed;
    right: 0;
    box-shadow: -9px 0px 38px -20px rgba(0, 0, 0, 0.4);
    overflow-y: scroll;
    @include md {
      width: 376px;
      overflow: unset;
    }
  }
  &__icon {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.24);
    width: 33px;
    height: 33px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    right: 20px;
    top: 23px;
    .svg {
      &--settings {
        .b {
          stroke: #fff;
        }
      }
    }
    @include md {
      background-color: #000;
      width: 37px;
      height: 37px;
      left: -48px;
      top: 18px;
      border-radius: 50%;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 22px;
    height: 80px;
  }
  &__title {
    font-size: 18px;
    font-weight: $weight__medium;
    color: #fff;
  }
  &__content {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
    padding: 45px 23px 0 23px;
    height: unset;
    flex: 1;
    @include md {
      height: 100%;
    }
  }
  &__group {
    margin-bottom: 15px;
  }
  &__group-label {
    font-size: 15px;
    font-weight: $weight__medium;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 28px;
  }
  &__group-item {
    border-radius: 8px;
    background-color: rgba(241, 241, 241, 0.51);
    padding: 11px 23px;
    color: rgba(17, 17, 17, 1);
    font-size: 12px;
    font-weight: $weight__regular;
    margin-bottom: 8px;
    cursor: pointer;
    &--logout {
      color: rgba(255, 51, 51, 1);
      margin-top: 50px;
    }
  }
  @media screen and (max-height: 760px) {
    &__content {
      padding: 25px 23px 0 23px;
    }
    &__group-label {
      margin-bottom: 15px;
    }
    &__group-item {
      margin-bottom: 5px;
      &--logout {
        margin-top: 20px;
      }
    }
  }
}
