@import './_reset';
@import './_variables';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
  height: 100%;
  -webkit-text-size-adjust: none;
  touch-action: none;
}
body {
  line-height: 1.5;
  font-size: 16px;
  height: 100%;
  -webkit-text-size-adjust: none;
  touch-action: none;
  font-family: $font__poppins;
}
.root {
  height: 100%;
}
.react-loading-skeleton {
  background-color: rgba(245, 245, 245, 0.55) !important;
  background-image: linear-gradient(90deg, rgba(245, 245, 245, 0.2), #ffffff8a, rgba(245, 245, 245, 0.2)) !important;
}

.addUserWarning {
  font-size: 14px;
  max-width: 375px;
  color: #636363;
}

.switch-field {
  width: 100%;
  margin-top: 15px;
  &--error {
    margin-top: 4px;
    font-size: 11px;
    font-weight: 400;
    color: #ff3333;
  }
  &__options {
    display: flex;
    align-items: center;
    border-radius: 7px;
    background-color: rgba(#a1a1a1, 0.13);
  }
  &__option {
    cursor: pointer;
    flex: 1 0;
    text-align: center;
    border-radius: 7px;

    font-size: 12px;
    line-height: 1.5;
    color: rgba(#111111, 0.24);
    padding: 7px;
    &--active {
      background-color: #fff;
      box-shadow: 0px 0px 4px 0px rgba(#00000014, 0.25);
      color: #111111;
    }
  }
}

.form_field {
  &--fileinput {
    label {
      cursor: pointer;
    }
    input {
      display: none;
    }
    &_error {
      position: relative;
      height: 10px;
      label {
        top: 5px;
        left: -3px;
      }
    }
    &_label {
      width: 100%;
      background-color: rgba(165, 165, 165, 0.1);
      border-radius: 7px;
      label {
        color: rgba(0, 0, 0, 0.5);
        font-size: 10px;
        line-height: 1.5;
        padding: 11px;
        display: block;
      }
      &--disabled {
        pointer-events: none;
      }
    }
    &_singleFile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 10px;
      line-height: 1.5;
      margin: 10px 0;
      padding: 13px;
      border-radius: 5px;
      box-shadow: -15px 0px 11px #0000000a;
      svg {
        width: 15px;
        margin-right: 10px;
        cursor: pointer;
      }
      .fileName {
        position: relative;
        bottom: 5px;
      }
    }
  }
  &--has_error {
    .form_field--fileinput_label {
      border: 1px solid rgba(255, 51, 51, 0.25) !important;
    }
  }
}
.tabs-toggle {
  &__options {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
  }
  &__option {
    flex: 1 0 calc(50% - 10px);
    text-align: left;
    background-color: rgba(#acacac, 0.06);
    margin: 5px;
    font-size: 12px;
    line-height: 1.5;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 10px 35px 10px 10px;
    &--check {
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #15b791;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      svg {
        .a {
          stroke: #fff;
        }
      }
    }
  }
}

.react-select__menu {
  padding-bottom: 5px !important;
}

.pagination-bottom {
  margin-bottom: 142px;
}
