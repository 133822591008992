.btn {
  background: none;
  outline: none;
  border: 0;
  border-radius: 8px;
  font-family: $font__poppins;
  font-size: 13px;
  font-weight: $weight__regular;
  padding: 9px 21px;
  cursor: pointer;
  .svg {
    margin-left: 8px;
    width: 30px;
  }
  &.small {
    font-size: 11px;
    padding: 7px 13px;
    .svg {
      width: 12px;
    }
  }
  &.right-icon {
    padding: 9px 5px 9px 21px;
    display: flex;
    align-items: center;
  }
  &.left-icon {
    padding: 8px 13px 8px 5px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    .svg {
      margin-right: 8px;
    }
    &--no-margin {
      .svg {
        margin-right: 0;
      }
    }
  }
  &.green {
    background-color: rgba($color__primary--green, 0.1);
    color: $color__primary--green;
  }
  &.light-gray {
    background-color: rgba(172, 172, 172, 0.06);
    color: rgba(0, 0, 0, 0.5);
  }
  &.light--gray-bold {
    background-color: rgba(241, 241, 241, 0.51);
    color: rgba(0, 0, 0, 0.72);
    font-weight: 700;
  }
  &.light--gray {
    background-color: rgba(241, 241, 241, 0.51);
    color: rgba(0, 0, 0, 0.72);
  }
  &.light-blue {
    background-color: rgba(4, 46, 183, 0.1);
    color: rgba(4, 46, 183, 1);
  }
  &.gray {
    background-color: rgba(165, 165, 165, 0.1);
    color: rgba(0, 0, 0, 0.5);
  }
  &.orange {
    background-color: rgba(255, 145, 0, 0.1);
    color: rgba(255, 145, 0, 1);
  }
  &.red {
    background-color: rgba(255, 51, 51, 0.1);
    color: rgba(255, 51, 51, 1);
    .svg {
      .a {
        stroke: rgba(255, 51, 51, 1);
      }
    }
  }
  &.transparent {
    background-color: transparent;
    color: rgba(180, 180, 180, 0.54);
    z-index: 1;
    .svg {
      &--add {
        width: 12px;
        height: 12px;
      }
    }
  }
  &.no-padding {
    padding: unset;
  }

  &.addNews {
    position: relative;
    top: 5px;
  }

  &--orange {
    padding: 8px;
    border-radius: 5px;
    background-color: rgba($color__orange, 0.05);
    font-size: 10px;
    line-height: 1.5;
    color: $color__orange;
    text-transform: uppercase;
  }
}

.orange-calendar {
  padding-right: 10px !important;
  svg {
    width: 15px !important;
    .b,
    .c {
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    .c {
      stroke: #ff9100;
      stroke-width: 2px;
    }
    .d,
    .e {
      stroke: none;
    }
    .e {
      fill: #ff9100;
    }
  }
}
