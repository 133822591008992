.home-page {
  display: flex;
  background-color: $color__primary--gray;
  min-height: 100vh;
  &__left {
    display: none;
    @include md {
      display: block;
      width: calc(100% - 375px);
      background-image: url(../../../files/home-page-background.png);
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &__right {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include md {
      width: 375px;
      box-shadow: -14px 0px 22px 7px rgba(0, 0, 0, 0.2);
      overflow-y: hidden;
    }
  }
  &__wrapper {
    padding: 32px 0 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: white;
    text-align: center;
    flex-grow: 1;
    transition: transform 0.3s;
    &--animated {
      transform: translateY(80px);
    }
  }
}
