.checkbox__wrapper {
  width: 100%;
  height: auto;
  margin-top: 15px;

  .radio__wrapper {
    width: 100%;
    height: 160px;
  }
}
