.calendar {
  .fc {
    height: calc(100vh - 126px);
  }
  .fc .fc-scrollgrid table {
    margin-left: 15px;
  }
  .fc .fc-scroller-liquid-absolute {
    padding-top: 12px;
  }
  .fc .fc-toolbar-title {
    font-family: $font__poppins;
    color: #d9d9d9;
    font-size: 20px;
    font-weight: $weight__bold;
  }
  .fc-theme-standard .fc-scrollgrid,
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: unset;
  }
  .fc th {
    text-align: left;
    color: rgba(0, 0, 0, 0.2);
    font-size: 12px;
    font-weight: $weight__medium;
  }
  .fc .fc-col-header-cell-cushion {
    padding: 2px 4px 15px 2px;
    margin-left: -7px;
  }
  .fc .fc-daygrid-day-top {
    flex-direction: row;
  }
  .fc .fc-daygrid-day-frame {
    display: flex;
    flex-direction: column;
    top: -10px;
  }
  .fc .fc-daygrid-day-number {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(168, 168, 168, 1);
    font-size: 11px;
    margin-left: -8px;
  }
  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    flex: 1;
    border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), rgba(187, 187, 187, 0.05)) 1 100%;
    border-left-width: 3px;
    border-left-style: solid;
    margin: 5px 0px 5px 0;
    padding: 0 5px 0 0;
  }
  .fc-daygrid-dot-event .fc-event-title {
    font-weight: $weight__regular;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.57);
    font-family: $font__poppins;
  }
  .fc .fc-daygrid-day.fc-day-active {
    background-color: #fff;
    opacity: 1;
    box-shadow: 0px 3px 33px rgba(0, 0, 0, 0.16);
    .fc-daygrid-day-number {
      width: 29px;
      height: 29px;
      background-color: rgba(0, 0, 0, 1);
      color: #fff;
      font-size: 15px;
      font-weight: $weight__bold;
      margin-left: -14px;
    }
    .fc-daygrid-day-events {
      border: unset;
    }
  }
  &__skeleton-container {
    .react-loading-skeleton {
      margin: 0 3px 10px 0;
    }
  }
  &__skeleton-header {
    display: flex;
    justify-content: space-between;
  }

  &__event-cell-title {
    color: #ff3333;
  }

  .fc .fc-daygrid-event-harness {
    overflow: hidden;
  }
  .fc .fc-daygrid-day.fc-day-today {
    background-color: #fff;
    .fc-daygrid-day-number {
      width: 20px;
      height: 20px;
      background-color: rgba(0, 0, 0, 1);
      color: #fff;
      font-size: 11px;
      font-weight: $weight__bold;
    }
    .fc-daygrid-day-events {
      border-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(187, 187, 187, 1)) 1 100%;
    }
  }
  .fc .fc-day-past {
    opacity: 0.4;
  }
  .fc .fc-daygrid-day-bottom {
    display: none;
  }
  .fc .fc-daygrid-event-harness {
    pointer-events: none;
  }
  .fc .fc-highlight {
    background: unset;
  }
  &__event-cell {
    display: flex;
    align-items: center;
    font-size: 11px;
    .svg {
      max-height: 10px;
      max-width: 15px;
      margin: 0 5px;
    }
  }

  .mobile-datepicker .month ul.date li {
    min-height: 34px;
  }

  .mobile__calendar--wrapper {
    overflow: auto;
    padding-top: 95px;
    // margin-top: -3220px;
    .mobile-datepicker {
      span {
        color: white;
      }
    }
    .changeBg {
      background-color: black;
    }
    .month-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
      color: white;
    }
    .days {
      display: none;
    }
    .date {
      .notificationCounter {
        width: 15px;
        height: 15px;
        background-color: orange;
        color: white;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        vertical-align: auto;
        z-index: 1;
        font-weight: bold;
      }
      .activeDayBorder {
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 10px;
      }
    }
  }
}
