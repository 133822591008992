.content {
  margin-top: 20px;
  width: 100%;
  height: 100%;
}

.mobile {
  width: 100%;
  height: 100vh;
}

.start {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 80px);
  padding-bottom: 50px;
}
