.searchView {
  width: 100%;
  height: calc(100vh - 73px);
  background-color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;

  &_container {
    width: 80%;
    height: auto;
    margin: auto;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: unset;

    &-title {
      width: 100%;
      font-size: 18px;
      padding: 20px;
      color: #545454;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
    }

    &-settlements {
      max-height: 380px;
      overflow: hidden;
      .settlementList {
        margin-left: 0;
      }
    }

    &-plots {
      max-height: 260px;
      overflow: hidden;
      padding: 10px 10px;
      position: relative;
      right: 12px;

      .ReactTable .rt-table {
        flex: auto 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        border-collapse: collapse;
        overflow: auto;

        .rt-tr-group {
          background-color: #fff;
          box-shadow: 0px 0px 9px rgb(0 0 0 / 7%);
          height: 52px;
          margin: 5px;

          .rt-tr {
            position: relative;
            padding: 10px 10px;
          }
        }
      }
    }
    &-users {
      max-height: 243px;
      overflow: hidden;
    }

    &-investors {
      max-height: 243px;
      overflow: hidden;
    }

    &-business_partners {
      max-height: 243px;
      overflow: hidden;
    }

    &-notifications {
      max-height: 490px;
      overflow: hidden;
      padding: 10px 10px;
      position: relative;
      right: 12px;
    }

    .show {
      max-height: unset;
      height: auto;
      overflow: unset;
    }
  }
}
