.dashboard-header {
  display: flex;
  // height: 73px;
  height: 80px;
  background-color: #000;
  position: sticky;
  top: 0;
  @include md {
    background-color: $color__dashboard-header;
  }

  &__left {
    width: 126px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  &__user-icon {
    width: 18px;
    height: 20px;
  }
  &__fullname {
    font-size: 10px;
    font-weight: $weight__bold;
    color: rgba(0, 0, 0, 0.74);
    line-height: 10px;
  }
  &__right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    @include md {
      width: calc(100% - 126px);
    }
  }
  &__search {
    display: flex;
    align-items: center;
    width: calc(100% - 100px);
    background-color: #fff;
    border-radius: 22px;
    height: 43px;
    padding: 13px 19px;
  }

  &__info {
    width: 40px;
  }

  &__clear {
    cursor: pointer;
  }

  &__input {
    border: none;
    outline: none;
    margin-left: 2px;
    width: 100%;
  }
  &__search,
  &__input,
  input[type='text']::placeholder {
    font-family: $font__poppins;
    font-size: 12px;
    font-weight: $weight__regular;
    line-height: 18px;
    color: rgba(84, 84, 84, 1);
  }
  &__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include md {
      width: 75px;
    }
  }
  &__action-icon {
    display: flex;
    align-items: center;
    height: 33px;
    width: 33px;
    justify-content: center;
    .svg {
      &--add {
        .a {
          stroke: #fff;
          @include md {
            stroke: #000;
          }
        }
      }
      &--settings {
        .b {
          stroke: #fff;
          @include md {
            stroke: #000;
          }
        }
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  &--mobile {
    justify-content: space-between;
    padding: 0 20px 35px 20px;
    height: 100px;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 3;
    .btnsWrapper {
      display: flex;
      justify-content: space-between;
      width: 80px;
    }
  }
  &__switch {
    align-items: flex-end;
    color: rgba(180, 180, 180, 0.54);
    display: flex;
    font-size: 14px;
    font-weight: 500;
    height: 100%;
    justify-content: center;
    &-button {
      padding: 5px 15px;
      cursor: pointer;
      display: block;
      text-decoration: none;
      color: hsla(0, 0%, 71%, 0.51);
      &--active {
        background-color: #fff;
        border-radius: 15px;
        color: rgba(0, 0, 0, 1);
        font-weight: 700;
      }
    }
  }
  &__search-mobile {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
  }
  &__filter {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
  }
}
