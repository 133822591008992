.subpage-link {
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin: 0 8px;
  transition: all 0.15s ease-in-out;
  &__amount {
    font-size: 14px;
    font-weight: $weight__bold;
    color: rgba(110, 110, 110, 1);
    margin-right: 5px;
  }
  &__label {
    font-size: 14px;
    font-weight: $weight__regular;
    color: rgba(180, 180, 180, 0.54);
  }
  &--active {
    background-color: rgba(15, 15, 15, 1);
    border-radius: 4px;
    padding: 0 20px;
    .subpage-link__amount {
      color: rgba(110, 110, 110, 1);
    }
    .subpage-link__label {
      color: #ffffff;
      font-weight: $weight__bold;
    }
  }
}
