.dashboard-routes {
  display: flex;
  &__left {
    width: 100%;
    @media (max-width: 1024px) {
      height: 100vh;
    }
    @include md {
      width: calc(100% - 375px);
    }
    &--full-width {
      width: 100%;
    }
  }
  &__right {
    width: 375px;
    height: 100vh;
    background-color: #000;
    display: flex;
  }
  &__wrapper {
    display: flex;
  }
  &__page-content {
    width: 100%;
    font-size: 14px;
    // background-color: rgba($color__dashboard-header, 0.55);
    background-color: #fff;
    @include md {
      // padding: 20px;
      padding: 0 20px 0 20px;
      width: calc(100% - 126px);
    }
  }
  // &__block {
  //   background-color: #fff;
  //   width: 100%;
  //   height: 70vh;
  //   align-self: flex-end;
  //   border-top-left-radius: 20px;
  //   border-top-right-radius: 20px;
  //   box-shadow: -9px 0px 38px -20px rgba(0, 0, 0, 0.1);
  // }
}
