.inputComparison {
  position: relative;
  background-color: rgba(172, 172, 172, 0.06);
  // margin: 25px 0;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding: 9px 9px;
  border-radius: 8px;
  min-width: 120px;
  &__wrapper--pointer {
    cursor: pointer;
  }
  &--label {
    position: absolute;
    color: rgba(0, 0, 0, 0.54);
    font-size: 10px;
    top: -6px;
    left: 10px;
  }
  &--first {
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
  }
  &--second {
    font-size: 12px;
    color: rgba(115, 115, 115, 1);
    margin-left: 5px;
  }
}
