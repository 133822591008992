.acceptOffer {
  .addOfferModal {
    &__header {
      padding-right: 0;
      &--half {
        display: flex;
        justify-content: space-between;
      }
      &--bottom {
        margin-top: 5px;
      }
      &--input {
        color: #15b791;
        padding-top: 5px;
        border-radius: 8px;
      }
    }
    &__price {
      background-color: rgba(172, 172, 172, 0.06);
    }
    &__newPrice {
      font-size: 15px;
      padding-top: 5px;
    }
    &--half {
      display: flex;
    }
  }
  &__comment {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.89);
    max-width: 380px;

    margin-bottom: 15px;
    &--inwst {
      margin-top: 50px;
      color: rgba(0, 0, 0, 0.45);
      p {
        max-width: 230px;
      }
    }
    &--admin {
      border-top: 1px solid rgba(243, 243, 243, 1);
      padding-top: 20px;
    }
    &--admin {
      border-bottom: 0;
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      &--data {
        width: 100%;
        color: rgba(0, 0, 0, 0.45);
        width: 100%;
        max-width: 100% !important;
      }
      span {
        width: 100%;
        text-align: end;
        font-size: 10px;
        color: rgba(0, 0, 0, 0.89);
        font-weight: 700;
      }
      p {
        max-width: 230px;
        text-align: end;
      }
    }
  }
}
