.dashboard-bottom-menu {
  position: fixed;
  // display: flex;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  // justify-content: space-between;
  padding: 0 10px 25px 10px;
  height: 82px;
  bottom: 0;
  width: 100%;
  box-shadow: 0px 7px 10px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 10;
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: rgba(0, 0, 0, 0.3);
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: color 0.1s ease-in-out;
    &--active {
      color: rgba(0, 0, 0, 1);
      .svg {
        opacity: 1;
        transform: scale(1.2);
        transition: all 0.05s ease-in-out;
      }
    }
  }
  &__icon {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 9px;
    svg {
      max-width: 25px;
      max-height: 20px;
      opacity: 0.41;
      transform: scale(1);
      transition: all 0.05s ease-in-out;
    }
  }
  &__label {
    font-size: 10px;
    font-weight: $weight__bold;
  }
}
