.investors {
  width: 100%;
  .select {
    &__filter {
      &--wrapper {
        display: flex;
        justify-content: space-between;
        width: 350px;
        align-items: center;
        height: 50px;
        .select-field__wrapper {
          width: 270px;
          .select-field {
            padding: 0 0 5px 0;
          }
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #b4b4b4;
    margin-bottom: 16px;

    width: 100%;
  }

  &__counter {
    color: #6e6e6e;
    font-weight: bold;
    margin-right: 5px;
  }

  &__title {
    color: rgba(180, 180, 180, 0.54);
    margin-left: 10px;
  }

  &__wrapper {
    padding: 0;
  }
}
.btn.no-padding {
  float: right;
}
.list__column {
  float: right;
}
.badge--prospect_form {
  background-color: rgba(21, 183, 145, 0.85);
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
}
.badge--in_form {
  background-color: rgba(255, 145, 0, 1);
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
}
.badge--out_form {
  background-color: rgba(255, 51, 51, 1);
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
}
//   .page-content {
//     &__wrapper {
//       padding: 20px 25px 20px 18px;
//       display: flex;
//       justify-content: space-between;
//     }
//   }
//   .dashboard-routes {
//     &__page-content {
//       padding: 0;
//     }
//   }
// }
