#settlements__top {
  @media (min-width: 756px) {
    position: relative;
  }
  .select-field__wrapper {
    @media (min-width: 756px) {
      justify-self: flex-end;
    }
    min-width: 200px;
  }
    .react-select__clear-indicator {
      display: none;
  }
}
.settlements {
  display: flex;
  &__top {
    @media (max-width: 756px) {
      padding-top: 120px;
    }
    .investors__title {
      height: 50px;
      display: flex;
      align-items: flex-end;
      font-weight: bold;
      @media (max-width: 756px) {
        align-items: center;
      }
    }
  }
  &__header {
    display: inline-block;
    font-size: 14px;
    color: #b4b4b4;
    margin-bottom: 16px;
    width: 100%;
    @media (max-width: 756px) {
      padding-right: 25px;
      padding-left: 10px;
    }
  }
  &__addNew {
    flex: 0 1 100px;
    padding-left: 15px;
  }
  &__list {
    width: 100%;
    overflow: auto;
  }
  &__title {
    font-size: 14px;
    line-height: 1.5;
    color: rgba(180, 180, 180, 0.54);
    margin-bottom: 15px;
    &--marginTop {
      margin-top: 30px;
    }
    @media (max-width: 756px) {
      display: none;
    }
  }
  .ReactTable {
    .rt-tr {
      cursor: pointer;
      border: 1px solid transparent;
      @media (max-width: 756px) {
        flex-wrap: wrap;
      }
    }
  }
  @media (max-width: 756px) {
    margin-top: -18px;
    padding-top: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #fff;
  }
}

.cell {
  &--company-name {
    white-space: normal !important;
    h3 {
      display: flex;
      align-items: center;
      svg {
        margin-right: 5px;
      }
    }
    @media (max-width: 756px) {
      width: calc(100% - 150px) !important;
    }
    width: auto !important;
    flex: unset !important;
    min-width: 100px !important;
  }
  &--name {
    svg {
      margin-right: 5px;
      width: 13px;
      height: 13px;
    }
    h3 {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;
      display: flex;
      margin-bottom: 17px;
      svg {
        flex-shrink: 0;
      }
    }
    p {
      line-height: 1.5;
      font-size: 12px;
      opacity: 0.97;
    }
  }
  &--thumbnail {
    max-width: 112px;
    margin: 0 auto;
    border-radius: 5px;
    overflow: hidden;
  }
  &--media {
    .media {
      &__lastUpdated {
        font-size: 11px;
        line-height: 1.5;
        color: rgba(43, 43, 43, 0.78);
        position: relative;
        padding-left: 10px;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 5px;
          height: 5px;
          border-radius: 5px;
          background-color: $color__primary--orange;
        }
      }
      &__list {
        margin-top: 20px;
        svg {
          margin-right: 10px;
        }
      }
    }
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.availabilityBadge {
  display: inline-block;
  position: relative;
  margin-left: 9px;
  &__count {
    padding: 3px 11px;
    background-color: rgba(48, 48, 48, 0.03);
    border-radius: 8px;
    font-size: 11px;
    line-height: 1.5;
    color: rgba(43, 43, 43, 0.78);
  }
  &__icon {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    position: absolute;
    left: -2px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #000000;
  }
  &--free {
    .availabilityBadge__icon {
      background-color: $color__primary--green;
    }
  }
  &--reservation,
  &--pending_cashouts {
    .availabilityBadge__icon {
      background-color: $color__primary--orange;
    }
  }
  &--pre_booking {
    .availabilityBadge__icon {
      background-color: $color__primary--orange;
    }
  }
  &--signed_contract {
    .availabilityBadge__icon {
      background-color: $color__primary--red;
    }
  }
  &--bought {
    .availabilityBadge__icon {
      background-color: #000000;
    }
  }
}
.settlementsAvailability {
  &__totalCount {
    font-size: 12px;
    font-weight: 600;
    color: #242424;
    margin-right: 20px;
  }
  &__header {
    margin-bottom: 20px;
  }
  &__sizes {
    &--element {
      font-size: 12px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.57);
      margin-bottom: 7px;
    }
  }
}

.settlementList {
  width: calc(100% - 24px);
  margin-left: 12px;

  &__wrapper {
    height: calc(100vh - 120px);
    width: calc(100% - 50px);

    .react-loading-skeleton {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      margin: 10px 5px 10px 0;
      width: 100%;
      background-color: rgba(245, 245, 245, 0.55);
      background-image: linear-gradient(90deg, rgba(245, 245, 245, 0.2), #ffffff8a, rgba(245, 245, 245, 0.2));
      background-size: 50px 100%;
    }

    @media (max-width: 756px) {
      width: calc(100% - 20px);
      margin-left: 10px;
      margin-top: 8px;
      // padding-top: 120px;
    }
  }
  .ReactTable .rt-tbody .rt-td {
    border: 0;
  }
  .rt-tr {
    border: unset !important;
    filter: drop-shadow(-15px 0px 11px #00000007);
  }
  .rt-tbody {
    padding: 5px 0 0 5px;
    @media (max-width: 756px) {
      padding: 0;
      max-width: 100vw;
      min-width: unset !important;
    }
  }
  .rt-td {
    // &:nth-child(2) {
    //   min-width: unset !important;
    //   width: unset !important;
    // }
    &:nth-child(3) {
      margin-left: auto;
      @media (max-width: 756px) {
        margin-left: 0;
      }
    }
  }
}
