// FONT FAMILY
$font__poppins: 'Poppins', sans-serif;

// FONT WEIGHT
$weight__light: 300;
$weight__regular: 400;
$weight__medium: 500;
$weight__bold: 700;
$weight__black: 900;

// COLORS
$color__primary: rgba(0, 0, 0, 0.03);
$color__primary--gray: rgba(0, 0, 0, 0.03);
$color__primary--green: rgba(21, 183, 145, 1);
$color__primary--orange: rgb(255, 145, 0);
$color__primary--red: #ff3333;
$color__primary--yellow: #f3ef09;
$color__input-border: rgba(241, 241, 241, 1);
$color__input-label: rgba(0, 0, 0, 0.4);
$color__dashboard-header: rgba(245, 245, 245, 1);
$color__menu-icon: rgba(0, 0, 0, 0.5);
$color__employees--ddi: rgba(203, 98, 98, 0.85);
$color__employees--advisers: rgba(99, 135, 255, 0.85);
$color__employees--accounting: rgba(129, 98, 203, 0.85);
$color__employees--administration: rgba(4, 46, 183, 0.85);
$color__employees--marketing: rgba(255, 145, 0, 0.85);
$color__employees--admin: rgba(0, 0, 0, 0.85);
$color__employees--dir: rgba(196, 183, 6, 0.85);
$color__employees--dp: rgba(28, 75, 43, 0.85);
$color__orange: #ff9100;
$color__investors: rgba(148, 163, 239, 0.85);
$color__status--in: rgba(21, 183, 145, 0.19);
$color__status--out: rgba(255, 51, 51, 0.09);
$color__status--prospect: rgba(255, 145, 0, 0.17);

// FONT COLORS

$font__color__status--in: rgba(21, 183, 145, 1);
$font__color__status--out: rgba(255, 51, 51, 1);
$font__color__status--prospect: rgba(255, 145, 0, 1);
