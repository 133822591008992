.historyPaymentModal {
  &__inputs {
    display: flex;
    justify-content: space-between;
    // border-bottom: 1px solid rgba(243, 243, 243, 1);
    padding-bottom: 20px;
  }
  &--donations {
    display: flex;
    flex-wrap: wrap;
    &--header {
      margin: 20px 0;
      font-size: 14px;
      color: rgba(99, 99, 99, 1);
    }
  }
  &__donation {
    width: 100%;
    font-size: 12px;
    &--date {
      color: rgba(0, 0, 0, 0.38);
    }
  }
}
