.partners {
  .select {
    &__filter {
      &--wrapper {
        display: flex;
        justify-content: space-between;
        width: 350px;
        align-items: center;
        height: 50px;
        .select-field__wrapper {
          width: 270px;
          .select-field {
            padding: 0 0 15px 0;
          }
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 21px;
    @include md {
      .btn {
        position: absolute;
        right: 0;
        top: 24px;
      }
    }
  }
  &__title {
    font-size: 15px;
    font-weight: $weight__medium;
    color: rgba(180, 180, 180, 0.54);
  }
  &__amount {
    color: rgba(110, 110, 110, 1);
    font-weight: $weight__bold;
    margin-right: 5px;
  }
  &__add {
    font-size: 14px;
    font-weight: $weight__regular;
    color: rgba(180, 180, 180, 0.55);
    display: flex;
    align-items: center;
    z-index: 1;
    .svg {
      height: 12px;
      width: 12px;
      margin-left: 9px;
      &--add {
        .a {
          stroke: rgba(214, 214, 214, 1);
          stroke-width: 4px;
        }
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__list {
    height: calc(100vh - 220px);
    overflow: scroll;
    @include md {
      height: calc(100vh - 170px);
    }
  }
}
