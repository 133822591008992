.employees-right {
  width: 100%;
  height: 100%;
  background-color: #000;
  position: fixed;
  overflow-y: auto;
  z-index: 2;
  font-size: 12px;
  @include md {
    color: #fff;
    box-shadow: -21px 0px 38px -20px rgba(0, 0, 0, 0.1);
    background-color: #000;
    position: unset;
  }

  .ReactTable .rt-tbody {
    padding: 6px;
  }

  .ReactTable .rt-tr {
    padding: 10px;
    position: relative;
  }

  .ReactTable .rt-tr-group {
    background-color: #fff;
    filter: drop-shadow(-15px 0px 11px #0000000a);
  }

  .ReactTable.table--sm .rt-td {
    color: black;
  }

  &__back {
    width: 24px;
    height: 24px;
    background-color: rgba(255, 255, 255, 0.37);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    .svg {
      height: 7px;
    }
  }
  &__title-wrapper {
    display: flex;
    align-items: center;
  }
  &__header {
    height: 140px;
  }
  &__title {
    height: 73px;
    // width: 336px;
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    border-bottom: 2px solid rgba(212, 212, 212, 0.06);
  }
  &__fullname {
    font-size: 18px;
    font-weight: $weight__bold;
    color: #d9d9d9;
    padding-left: 20px;
  }
  &__phone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: rgba($color__primary--green, 0.1);
    border-radius: 8px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
    padding: 26px 23px 0 23px;
    min-height: calc(100% - 73px);
    height: auto;
  }
  &__submenu {
    height: 50px;
    padding: 3px 22px;
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
  &__submenu-item {
    font-size: 14px;
    font-weight: $weight__regular;
    color: rgba(180, 180, 180, 0.54);
    cursor: pointer;
    padding: 0 22px;
    &--active {
      border-radius: 15px;
      background-color: rgba(255, 255, 255, 0.09);
      font-weight: $weight__bold;
      color: rgba(255, 255, 255, 1);
      padding: 5px 20px;
    }
  }
  &__data {
    color: gray;
  }
  &__group-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 18px;
  }
  &__group-label {
    color: rgba(99, 99, 99, 1);
    font-size: 14px;
    font-weight: $weight__regular;
    padding-bottom: 13px;
  }
  &__select {
    background-color: rgba(241, 241, 241, 0.51);
    border-radius: 8px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 12px;
    font-size: 12px;
    font-weight: $weight__regular;
    font-family: $font__poppins;
    margin-top: 10px;
    cursor: pointer;

    .settlement {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .settlement-name {
        margin-left: 10px;
      }
    }
  }

  &__container {
    display: flex;
    justify-content: flex-start;
    .employees-right__field-wrapper {
      &:first-of-type {
        width: 50%;
      }
    }
  }
  &__field-wrapper {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    padding-bottom: 20px;
  }
  &__field-label {
    font-size: 11px;
    font-weight: $weight__regular;
    color: rgba(0, 0, 0, 0.41);
    padding-bottom: 4px;
  }
  &__input {
    font-size: 12px;
    font-weight: $weight__regular;
    color: rgba(17, 17, 17, 0.97);
    &--phone {
      color: rgba(17, 17, 17, 0.22);
      margin-right: 13px;
    }
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
  }
  .react-loading-skeleton {
    background-size: 100px 100% !important;
  }
}

.active-tile {
  background-color: white;
  box-shadow: -6px 0px 13px -2px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 12px;
  font-size: 12px;
  font-weight: $weight__regular;
  font-family: $font__poppins;
  margin-top: 10px;
}
