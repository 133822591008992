.employees {
  background-color: #000;
  &__wrapper {
    padding: 19px 17px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    position: relative;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 29px;
  }
  &__title {
    font-size: 15px;
    font-weight: $weight__medium;
    color: rgba(0, 0, 0, 0.65);
  }
  &__add {
    font-size: 14px;
    font-weight: $weight__regular;
    color: rgba(180, 180, 180, 0.55);
    display: flex;
    align-items: center;
    z-index: 1;
    .svg {
      height: 12px;
      width: 12px;
      margin-left: 9px;
      &--add {
        .a {
          stroke: rgba(214, 214, 214, 1);
          stroke-width: 4px;
        }
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__subpages {
    padding-bottom: 20px;
    @include md {
      padding-bottom: 15px;
    }
  }
  &__skeleton-subpage {
    span {
      width: 1000px;
      display: block;
      overflow: hidden;
    }
    .react-loading-skeleton {
      float: left;
      border-radius: 8px;
      width: 100px;
      margin: 0 5px 20px 0;
      background-color: rgba(245, 245, 245, 0.55);
      background-image: linear-gradient(90deg, rgba(245, 245, 245, 0.2), #ffffff8a, rgba(245, 245, 245, 0.2));
      background-size: 50px 100%;
    }
  }
  &__routes {
    // padding-top: 20px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 280px);
    overflow: auto;
    &--no-scroll {
      overflow: hidden;
    }
  }
  @include md {
    background-color: #fff;
    &__title {
      display: none;
    }
    &__header {
      position: absolute;
      right: 0;
      top: 24px;
    }
    &__routes {
      height: calc(100vh - 180px);
    }
    &__skeleton-subpage {
      .react-loading-skeleton {
        border-radius: 8px;
        margin: 0 5px 20px 0;
        background-size: 50px 100%;
      }
    }
  }
}

.slick-list {
  padding: 0 30px !important;
  @include md {
    padding: 0 0px !important;
  }
  margin: 0 !important;
}

.slick-list > div {
  margin-left: 0 !important;
}
