.map {
  height: 100%;
  overflow: hidden;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
  &__wrapper {
    padding: 0 !important;
  }
  &__item {
    position: relative;
    overflow-x: scroll;
    overflow-y: scroll;
    &--24 {
      margin-top: -100px;
    }
    @media (max-width: 756px) {
      &--4 {
        margin-left: -1300px !important;
        margin-top: -400px !important;
        margin-bottom: -100px !important;
      }
      &--7 {
        margin-left: -200px !important;
      }
      &--9 {
        margin-left: -850px !important;
        margin-top: -300px !important;
        margin-bottom: -300px !important;
        margin-right: -200px !important;
      }
      &--16 {
        margin-left: -300px !important;
        margin-right: -300px !important;
      }
      &--17 {
        margin-left: -350px !important;
        margin-bottom: -200px !important;
      }
      &--18 {
        margin-left: -800px !important;
        margin-bottom: -200px !important;
        margin-right: -400px !important;
      }
      &--19 {
        margin-top: -400px !important;
        // margin-right: -300px !important;
      }
      &--23 {
        margin-right: -500px !important;
      }
    }
  }
  &--24 {
    width: 500px;
  }
  &--mobile {
    width: 100%;
    overflow: scroll;
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
    .map__item {
      margin-top: 0;
      margin-bottom: 50px;
      &--24 {
        margin-top: -100px;
      }
    }
  }
  .plot__header {
    background-color: #000;
    min-height: auto;
    padding-bottom: 30px;
  }
  .settlementName__name {
    color: rgba(217, 217, 217, 1);
    text-transform: capitalize;
  }
  &__settlement {
    &--plot {
      cursor: pointer;
      // fill: black;
    }
    &--4 {
      margin-left: -100px;
      margin-top: -60px;
    }
    &--24 {
      margin-left: -250px;
    }
    @media (max-width: 1024px) {
      &--24 {
        margin-left: -680px;
      }
      &--23 {
        margin-left: -180px;
      }
      &--7 {
        margin-left: -880px;
        margin-top: -80px;
      }
      &--16 {
        margin-left: -700px;
      }
      &--19 {
        margin-left: -1200px;
        margin-bottom: -150px;
      }
      // &--6 {
      // margin-left: -100px;
      // margin-right: 1000px;
      // }
      &--10 {
        // margin-left: -500px;
        // margin-bottom: -100px;
        // margin-top: -80px;
        margin-left: -900px;
        margin-bottom: -331px;
        margin-top: -80px;
      }
      &--3 {
        margin-bottom: -300px;
        margin-right: -100px;
        width: 800px;
      }
    }
  }
  &__plot {
    &-pending_cashouts,
    &-reservation {
      fill: #f3e0af;
      cursor: pointer;
    }
    &-free {
      fill: #8aad97;
      cursor: pointer;
    }
    &-signed_contract {
      fill: #e48583;
      cursor: pointer;
    }
    &-bought {
      fill: #e48583;
      cursor: pointer;
    }
    &--active {
      box-shadow: 10px 5px 5px black;
      stroke: #fff;
      stroke-width: 5;
      z-index: 9999;
      filter: drop-shadow(0px 3px 22px rgba(0, 0, 0, 0.53));
    }
  }
  &__details {
    z-index: 1;
    position: fixed;
    bottom: 25px;
    right: 20px;
    width: 450px;
    display: flex;
    padding: 8px 10px;
    box-shadow: -15px 2px 13px rgba(0, 0, 0, 0.26);
    background-color: #fff;
    font-size: 9px;
    border-radius: 5px;
    justify-content: space-between;
    @media (max-width: 756px) {
      bottom: 90px !important;
    }
    &--link {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 90px);
      height: 100%;
      cursor: pointer;
      z-index: 2;
    }
    &--close {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &--ddi {
      padding-top: 3px;
    }

    &--mobile {
      bottom: 50px;
    }
    .availabilityBadge {
      display: flex;
      align-items: center;
    }
    &--surface {
      color: rgba(0, 0, 0, 0.48);
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 10px;
      margin-left: 10px;
      &:after {
        content: '';
        width: 5px;
        height: 5px;
        display: block;
        position: absolute;
        background-color: rgba(90, 90, 90, 0.48);
        left: 1px;
        border-radius: 5px;
      }
    }
    &--price {
      color: rgba(0, 0, 0, 0.48);
      margin-left: 10px;
      padding: 0 5px;
      display: flex;
      align-items: center;
    }
    .btn {
      padding: 9px 12px;
    }
  }
}

.map-desktop {
  height: calc(100vh - 200px);
  width: calc(100vw - 120px);
  overflow: auto;
  margin-left: -10px;
  &--close {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 999;
  }
  &--20 {
    overflow: hidden;
    max-width: 1080px;
    max-height: 650px;
    @media (max-width: 1024px) {
      max-width: 600px;
    }
  }

  &--22 {
    max-width: 1080px;
    max-height: 750px;
  }

  &--23 {
    overflow: scroll;
    max-width: 1280px;
    max-height: 850px;
    margin-left: -200px;
  }
  &--19 {
    overflow: hidden;
    max-width: 1280px;
    max-height: 850px;
    svg {
      margin-left: -500px;
      margin-top: -200px;
      @media (max-width: 1200px) {
        margin-left: -800px;
      }
    }
  }
  &--18 {
    max-width: 1050px;
    max-height: 800px;
    overflow: hidden;
    svg {
      margin-left: -800px;
      margin-top: -100px;
    }
  }
  &--17 {
    max-width: 1050px;
    overflow: hidden;
  }
  &--10 {
    max-width: 1050px;
    overflow: hidden;
    svg {
      margin-left: -450px;
      margin-top: -150px;
    }
  }
  &--9 {
    max-width: 1050px;
    overflow: hidden;
    svg {
      margin-left: -850px;
      margin-top: -150px;
    }
  }
  &--7 {
    max-width: 1200px;
    overflow-y: auto;
    max-height: 980px;
    svg {
      margin-left: -880px;
      margin-top: -100px;
    }
    .map__item {
      overflow-x: hidden;
    }
  }
  &--6 {
    max-width: 1280px;
    max-height: 800px;
    overflow-y: scroll;
    .map__item {
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }
  &--4 {
    max-width: 1280px;
    max-height: 800px;
    overflow-y: scroll;
    svg {
      margin-left: -600px;
      margin-top: -200px;
    }
    .map__item {
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }
  &--3 {
    max-width: 1280px;
    max-height: 800px;
    overflow-y: hidden;
    svg {
      margin-left: -500px;
      margin-top: -200px;
    }
    .map__item {
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }
  &--16 {
    max-width: 1580px;
    max-height: 800px;
    svg {
      margin-left: -100px;
      margin-top: -50px;
    }
    .map__item {
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }
}
