.single-news {
  margin: 0 7px;
  &__box {
    width: 195px;
    background-color: rgba(255, 255, 255, 0.06);
    border-radius: 6px;
    padding: 17px 11px;
    height: 150px;
  }
  &__title {
    font-size: 15px;
    font-weight: $weight__bold;
    color: rgba(255, 255, 255, 0.73);
    margin-bottom: 8px;
  }
  &__description {
    font-size: 10px;
    font-weight: $weight__regular;
    color: rgba(255, 255, 255, 0.31);
  }
  &__information {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0;
  }
  &__user {
    font-size: 11px;
    font-weight: $weight__medium;
    color: rgba(255, 255, 255, 0.6);
    margin-right: 7px;
  }
  &__date {
    font-size: 11px;
    font-weight: $weight__medium;
    color: rgba(255, 255, 255, 0.35);
    margin-right: 5px;
  }

  &__remove {
    position: relative;
    left: 10px;
    bottom: 2px;
    color: rgba(255, 255, 255, 0.31);
  }

  &__edit {
    font-size: 10px;
    font-weight: $weight__medium;
    position: relative;
    left: 15px;
  }

  @media (max-width: 1300px) {
    &__edit {
      font-size: 10px;
      font-weight: $weight__medium;
      position: relative;
      left: 15px;
      color: rgba(255, 255, 255, 0.31);
    }
  }

  @include md {
    &__box {
      background-color: #fff;
      box-shadow: -6px 0px 13px -2px rgba(0, 0, 0, 0.08);
      border-radius: 0;
    }
    &__title {
      color: rgba(0, 0, 0, 0.65);
      font-weight: $weight__medium;
    }
    &__description,
    &__user {
      color: rgba(0, 0, 0, 0.42);
    }
    &__date {
      color: rgba(0, 0, 0, 0.2);
    }
  }
}
