.page-content {
  background-color: #000;
  &__wrapper {
    padding: 19px 17px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #fff;
    position: relative;
  }
  @include md {
    background-color: #fff;
  }
}
