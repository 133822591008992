.news {
  background-color: #000;
  padding: 5px 20px;
  overflow: hidden;

  &__title {
    font-size: 18px;
    font-weight: $weight__bold;
    color: rgba(217, 217, 217, 1);
    border-bottom: 2px solid rgba(212, 212, 212, 0.1);
    width: 100%;
    height: 37px;
  }
  &__items-wrapper {
    padding-top: 10px;
    .slick-list {
      overflow: unset;
    }
    .slick-slide {
      cursor: pointer;
    }
  }
  @include md {
    background-color: unset;
    &__title {
      color: rgba(0, 0, 0, 0.65);
      border-bottom: 2px solid rgba(212, 212, 212, 0.15);
    }
  }
}
