.userBar {
  display: flex;
  background-color: rgba(241, 241, 241, 0.51);
  padding: 11px 14px;
  border-radius: 8px;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  color: rgba(17, 17, 17, 0.97);
  font-size: 12px;
  &__wrapper {
    display: flex;
    padding: 0 2px;
    a {
      height: 45px;
      width: 45px;
      margin-left: 10px;
    }
  }
  &--name {
    margin-left: 10px;
  }
}
