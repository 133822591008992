.reset-password {
  &__form {
    max-width: 266px;
    margin: 0 auto;
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // flex-direction: column;
    .btn {
      align-self: flex-end;
    }
  }
  &__description {
    font-size: 10px;
    font-weight: $weight__regular;
    color: rgba(#000, 0.42);
    padding-bottom: 30px;
  }
  &__back {
    font-size: 11px;
    font-weight: $weight__regular;
    color: rgba(#000, 0.19);
    cursor: pointer;
    text-decoration: none;
  }
  // &__submit {
  //   align-self: flex-end;
  // }
  &--shadowed {
    opacity: 0.4;
  }
}
