.reservationModal {
  &__header {
    background-color: rgba(172, 172, 172, 0.06);
    border-radius: 8px;
    padding: 18px;
    width: 100%;
    margin-bottom: 15px;
    &--top,
    &--bottom {
      display: flex;
      align-items: center;
    }
    &--bottom {
      margin-top: 20px;
      .media__list--item {
        margin: 0 5px;
      }
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    &-second {
      margin-top: 30px;
      margin-bottom: -12px;
    }
  }
  &--deposit {
    font-size: 11px;
    color: rgba(43, 43, 43, 0.61);
    &-price {
      display: flex;
      width: 50%;
      justify-content: flex-end;
    }
  }
  &--icon {
    height: 16px;
  }
  &--number {
    color: rgba(0, 0, 0, 0.12);
    font-weight: 700;
    font-size: 10px;
    margin: 0 7px;
  }
  &--plotName {
    color: rgba(3, 3, 3, 0.57);
    font-size: 10px;
    font-weight: 700;
  }
  &__plot-number {
    align-items: center;
    background-color: rgba(48, 48, 48, 0.03);
    border-radius: 8px;
    color: rgba(43, 43, 43, 0.78);
    display: flex;
    font-size: 11px;
    margin-right: 5px;
    padding: 4px 8px;
    position: relative;
  }
  &--dot {
    background-color: rgba(21, 183, 145, 1);
    display: block;
    border-radius: 5px;
    height: 5px;
    width: 5px;
    position: absolute;
    left: -2px;
  }
  &__yardage {
    font-size: 11px;
    color: rgba(0, 0, 0, 0.48);
    margin-left: 5px;
    display: flex;
    align-items: center;
    &--dot {
      background-color: rgba(90, 90, 90, 0.48);
      display: block;
      border-radius: 5px;
      height: 5px;
      width: 5px;
      margin-right: 5px;
    }
  }
  &--price {
    font-size: 15px;
    margin-left: auto;
    &-green {
      font-size: 14px;
      color: rgba(21, 183, 145, 1);
      width: 50px;
      text-align: right;
    }
    &-deposit-percentage {
      width: 50px;
      font-size: 14px;
      text-align: right;
    }
    &-gray {
      color: rgba(86, 86, 86, 0.61);
      font-size: 12px;
    }
  }
  &--heading {
    color: rgba(99, 99, 99, 1);
    font-size: 14px;
    margin-bottom: 18px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    &-error {
      font-size: 11px;
      color: #ff3333;
      margin-right: 5px;
    }
  }
  &__offer {
    display: flex;
    align-items: center;
    flex-direction: column;
    &--item {
      display: flex;
      align-items: center;
      padding: 10px 13px 8px 13px;
      background-color: rgba(172, 172, 172, 0.06);
      border-radius: 8px;
      width: 100%;
      margin: 3px 0;
      cursor: pointer;
      &--half {
        display: flex;
        width: 50%;
        align-items: center;
      }
      &--active {
        background-color: rgba(255, 255, 255, 1);
        filter: drop-shadow(-15px 0px 12px rgba(0, 0, 0, 0.07));
      }
    }
    &--price {
      font-size: 15px;
      margin-right: 11px;
    }
    &--discount {
      font-size: 15px;
      color: rgba(21, 183, 145, 1);
    }
    &--deposit {
      font-size: 11px;
      color: rgba(43, 43, 43, 0.61);
      display: flex;
      &-price {
        font-size: 12px;
        margin-left: 13px;
      }
    }
    &-icon {
      display: none;
      &--active {
        display: block;
        position: absolute;
        right: 10px;
      }
    }
  }
  &--footer {
    color: rgba(0, 0, 0, 0.57);
    font-size: 11px;
    margin-top: 20px;
    line-height: 17px;
  }
}
