* {
  margin: 0;
  padding: 0;
}

.select-field {
  padding: 10px 0 5px 0;
}

.calendar__wrapper {
  display: flex;

  &--picker {
    margin: 10px;
  }
}

.responsive-calendar {
  font-size: 8px !important;
  z-index: 0;
}
