.dashboard-sidebar {
  width: 126px;
  display: flex;
  flex-direction: column;
  background-color: rgba($color__dashboard-header, 0.55);
  height: calc(100vh - 80px);
  padding: 0 0 30px;
  &__menu {
    margin-top: 20px;
    flex: 1;
  }
  &__item {
    display: flex;
    align-self: center;
    margin-bottom: 20px;
    color: rgba(0, 0, 0, 0.3);
    text-decoration: none;
    &--active,
    &:hover {
      color: rgba(0, 0, 0, 1);
      .svg {
        opacity: 1;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__icon {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    svg {
      max-width: 19px;
      max-height: 13px;
      opacity: 0.41;
    }
  }
  &__label {
    font-size: 10px;
    font-weight: $weight__bold;
    line-height: 19px;
    margin-left: 5px;
  }
  &__logo {
    text-align: center;
    img {
      height: 60px;
    }
  }
}
