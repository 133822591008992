.list {
  &__item-wrapper {
    position: relative;
  }
  &__item {
    background-color: rgba(241, 241, 241, 0.51);
    border-radius: 8px;
    margin-bottom: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 7px;
    width: calc(100% - 50px);
    .badge {
      margin-right: 15px;
    }
    &--active,
    &:hover {
      @include md {
        background-color: #fff;
        box-shadow: 10px 0px 11px rgba(0, 0, 0, 0.04);
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  &__skeleton-item {
    .react-loading-skeleton {
      border-radius: 8px;
      margin-bottom: 20px;
      background-color: rgba(245, 245, 245, 0.55);
      // background-image: linear-gradient(90deg, #fcfcfc, #ffffff, #fcfcfc);
      // background-image: linear-gradient(90deg, rgba(245, 245, 245, 0.55), #ffffffa8, rgba(245, 245, 245, 0.55));
      background-image: linear-gradient(90deg, rgba(245, 245, 245, 0.2), #ffffff8a, rgba(245, 245, 245, 0.2));
      background-size: 180px 100%;
    }
  }
  &__empty {
    text-align: center;
    padding-top: 30px;
    color: rgb(138, 137, 137);
  }
  &__left,
  &__right {
    display: flex;
    align-items: center;
  }
  &__phone {
    position: absolute;
    right: 0px;
  }
  &__phone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: rgba($color__primary--green, 0.1);
    border-radius: 8px;
  }
  &__left {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  &__left-wrapper {
    display: flex;
  }
  &__columns-wrapper {
    display: flex;
    align-items: center;
  }
  &__column {
    padding: 0 15px;
    font-size: 10px;
    font-family: $font__poppins;
    font-weight: $weight__regular;
    color: #a8a8a8;
    &--bold {
      color: #111111;
      font-size: 12px;
    }
    &--status-active {
      color: #15b791;
    }
    &--status-inactive {
      color: #c1c1c1;
    }
  }
  @include md {
    width: calc(100% - 24px);
    padding-top: 8px;
    &__item {
      height: 52px;
      padding: 0 15px;
      margin-bottom: 7px;
      width: 100%;
    }
    &__phone {
      position: unset;
      right: unset;
      background-color: transparent;
    }
    &__skeleton-item {
      .react-loading-skeleton {
        margin-bottom: 7px;
      }
    }
  }
  &--mobile {
    .list {
      &__item {
        width: calc(100% - 87px);
      }
      &__phone {
        &:first-of-type {
          right: 44px;
        }
        &:nth-of-type(2) {
          right: 0;
        }
      }
    }
  }
}
