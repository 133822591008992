.svg {
  &__map {
    &--kanal-elblaski {
      .st0 {
        fill: #f4f8e9;
      }
      .st1 {
        fill: none;
        stroke: #ffffff;
        stroke-width: 2.6823;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
      }
      .st2 {
        fill: none;
        stroke: #ffffff;
        stroke-width: 2.6823;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
        stroke-dasharray: 6.6026, 9.904;
      }
      .st3 {
        fill: #ffffff;
      }
      .st4 {
        fill: #8ec4a3;
      }
      .st5 {
        fill: #74ae9e;
      }
      .st6 {
        fill: #7c6434;
      }
      .st7 {
        fill: #d2e5c1;
      }
      .st8 {
        fill: #f8e069;
      }
      .st9 {
        fill: none;
      }
      .st10 {
        opacity: 0.55;
      }
      .st11 {
        font-family: 'ProximaNova-Semibold';
      }
      .st12 {
        font-size: 28px;
      }
      .st13 {
        opacity: 0.35;
      }
      .st14 {
        font-family: 'SignikaNegative-Semibold';
      }
      .st15 {
        font-size: 20px;
      }
      .st16 {
        fill: #95d1e2;
      }
      .st17 {
        opacity: 0.65;
      }
      .st18 {
        fill: none;
        stroke: #7d6435;
        stroke-width: 1.4173;
        stroke-miterlimit: 10;
      }
    }
  }
  &--start {
    .a {
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    .b,
    .c {
      stroke: none;
    }
    .c {
      fill: #000;
    }
  }
  &--checked {
    .a {
      fill: #15b791;
    }
    .b {
      fill: none;
      stroke: #fff;
      stroke-width: 2px;
    }
  }
  &--navigate {
    .a {
      fill: #042eb7;
      stroke: #042eb7;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
  &--dot {
    .a {
      fill: #ff9100;
    }
  }
  &--arrow-gray {
    .a {
      opacity: 0.11;
    }
  }
  &--loupe {
    .a {
      fill: #fff;
    }
    &--gray {
      .a {
        fill: #8a8a8a !important;
      }
    }
  }
  &--back {
    .a {
      fill: none;
      stroke: #000;
      stroke-linecap: round;
      stroke-width: 2px;
    }
  }
  &--download {
    .a {
      fill: none;
      stroke: #fff;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 3px;
    }
    &--black {
      width: 13px;
      .a {
        fill: none;
        stroke: #4d4d4d;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 3px;
      }
    }
  }
  &--filter {
    &-icon {
      width: 30px;
      height: 30px;
      padding: 5px;
      border-radius: 6px;
      &--gray {
        background-color: rgba(255, 255, 255, 0.1);
      }
      &--black {
        background-color: black;
      }
    }
    .a {
      fill: none;
      stroke: #fff;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
    }

    &--black {
      .a {
        stroke: #000;
      }
    }
  }
  &--settlement {
    .a,
    .b {
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    .b {
      stroke: #000;
      stroke-width: 2px;
    }
    .c,
    .d {
      stroke: none;
    }
    .d {
      fill: #000;
    }
    &.white {
      .b {
        stroke: #fff;
      }
      .d {
        fill: #fff;
      }
    }
  }
  &--investors {
    .a {
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    .b,
    .c {
      stroke: none;
    }
    .c {
      fill: #000;
    }
  }
  &--employees {
    .a {
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    .b,
    .c {
      stroke: none;
    }
    .c {
      fill: #000;
    }
  }
  &--partners {
    .a {
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      //   opacity: 0.41;
    }
    .b,
    .c {
      stroke: none;
    }
    .c {
      fill: #000;
    }
  }
  &--calendar {
    // .a {
    //     opacity: 0.41;
    // }
    // .b,
    // .c {
    //   fill: none;
    //   stroke-linecap: round;
    //   stroke-linejoin: round;
    // }
    // .c {
    //   stroke: #000;
    //   stroke-width: 2px;
    // }
    // .d,
    // .e {
    //   stroke: none;
    // }
    // .e {
    //   fill: #000;
    // }
    .b,
    .c {
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
    .c {
      stroke: #000;
      stroke-width: 2px;
    }
    .d,
    .e {
      stroke: none;
    }
    .e {
      fill: #000;
    }
  }
  &--settings {
    .b {
      fill: none;
      stroke: #000;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
    }
  }
  &--add {
    .a {
      fill: none;
      // stroke: #000;
      stroke: #d6d6d6;
      stroke-linecap: round;
      stroke-width: 3px;
    }
  }
  &--phone {
    .a {
      fill: #15b791;
    }
  }
  &--confirm {
    .a {
      fill: none;
      stroke: rgba(255, 145, 0, 1);
      stroke-linecap: round;
      stroke-width: 2px;
    }
    &--green {
      .a {
        fill: none;
        stroke: #15b791;
        stroke-linecap: round;
        stroke-width: 2px;
      }
    }
  }
  &--dropdown-indicator {
    .a {
      fill: none;
      stroke: #0a0a0a;
      stroke-linecap: round;
      stroke-width: 3px;
    }
  }
  &--media_water {
    a {
      fill: #333;
    }
  }
  &--media_power {
    .a {
      fill: #333;
    }
  }
  &--media_heat {
    .a {
      fill: #333;
    }
  }
  &--blueHome {
    .a {
      fill: none;
      stroke: #6387ff;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
    }
  }
  &--map {
    .a {
      fill: none;
      stroke: #000;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
    }
    &-checked {
      .a {
        fill: none;
        stroke: #000;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px;
      }
      background-color: #fff;
      padding: 5px;
      width: 35px;
      height: 35px;
      border-radius: 7px;
    }
  }
  &--file {
    .a {
      fill: none;
      stroke: #000;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 1px;
    }
    &--green {
      .a {
        stroke: rgba(21, 183, 145, 1);
      }
    }
  }
  &--mail {
    .a {
      fill: #15b791;
    }
  }
  &--exit {
    .a {
      opacity: 0.21;
    }

    @media (max-width: 1600px) {
      .a {
        opacity: 1;
      }
    }

    .b {
      fill: none;
      stroke: #707070;
    }
  }
  &--restore {
    .a {
      fill: #ff9100;
      stroke: #ff9100;
    }
  }
  &--meeting {
    .a {
      fill: #004a9e;
      stroke: #004a9e;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }

  &--add_notification {
    .cls-1 {
      fill: grey;
      opacity: 0.24;
    }

    .cls-2,
    .cls-4 {
      fill: none;
    }

    .cls-2 {
      stroke: #ffcf33;
      stroke-linecap: round;
      stroke-width: 2px;
    }

    .cls-3 {
      stroke: none;
    }

    .inActive {
      stroke: grey;
      stroke-linecap: round;
      stroke-width: 2px;
    }
  }

  &--note {
    stroke-linecap: 'round';
    stroke-linejoin: 'round';
    stroke-width: '1';
  }

  &--appointment {
    stroke-linecap: 'round';
    stroke-linejoin: 'round';
    stroke-width: '1';
  }

  &--light-meeting {
    .a {
      fill: none;
      stroke: #000;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
  &--light-phone {
    .a {
      fill: none;
      stroke: #000;
    }
  }

  &--test {
    .a {
      fill: none;
      stroke: #1d1d1d;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
    }
  }

  &--light-file {
    .a {
      fill: none;
      stroke: #000;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }

  &--upload {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
  }
}
