.statuses {
  &__title {
    font-size: 18px;
    font-weight: $weight__bold;
    color: rgba(0, 0, 0, 0.65);
    width: 100%;
    height: 37px;
    margin: 20px 0 0 20px;
    margin-right: auto;
  }

  &__slider {
    margin-top: 20px;

    &--item {
      padding-top: 4px;
    }

    .slick-list {
      padding: 0 10px !important;
    }

    .slick-slide {
      padding: 0 8px;
      font-weight: bold;
    }

    .active {
      background-color: black;
      color: white;
      font-size: 13px;
      height: 30px;
      font-weight: bold;
      margin: 0;
      transition: 0.1s linear;
      border-radius: 5px;
      padding: 0 12px;
    }
  }

  &__mobileContent {
    margin: 30px 0 0 0;

    ul {
      margin-bottom: 30px;
    }

    &--listItem {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .settlementLinkWrapper {
        display: flex;
        align-items: center;
      }

      &-days {
        color: rgba(255, 51, 51, 1);
        right: 10px;
        bottom: 10px;
      }

      .investor {
        width: 15px;
      }

      .blue_home {
        width: 15px;
        flex-shrink: 0;
      }

      .arrow {
        position: relative;
        bottom: 8px;
      }
    }
  }

  &__content {
    margin: auto;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: -6px 0px 13px -2px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
    // align-items: center;
    padding: 20px 0;

    &--item:nth-child(1) {
      border-left: unset;
    }

    &--item {
      border-left: 3px solid rgba(187, 187, 187, 0.5);
      width: 550px;
      min-height: 100%;
      flex-grow: 1;
      padding: 0 20px;

      @media (max-width: 1740px) {
        &:nth-child(3) {
          border-left: unset;
        }
      }
    }

    &--offer:nth-child(1) {
      border-left: unset;
    }

    &--offer {
      border-left: 3px solid rgba(187, 187, 187, 0.5);
      width: 400px;
      min-height: 100%;
      flex-grow: 1;
      padding: 0 20px;

      @media (max-width: 1340px) {
        &:nth-child(2) {
          border-left: unset;
        }
      }
    }
  }
}

.list__header {
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;

  &--item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &--title {
    margin-left: 10px;
  }

  &--show {
    cursor: pointer;
  }

  &--counter {
    width: 20px;
    height: 20px;
    background-color: rgba(255, 207, 51, 0.3);
    border-radius: 50%;
    color: rgba(225, 172, 0, 1);
    font-weight: bold;
    text-align: center;
    font-size: 12px;
  }

  .reservations {
    background-color: rgba(255, 145, 0, 1);
    color: white;
  }

  .assigned {
    background-color: rgba(255, 51, 51, 1);
    color: white;
  }

  .waiting {
    background-color: rgba(0, 0, 0, 0.67);
    color: white;
  }

  .accepted {
    background-color: rgba(21, 183, 145, 1);
    color: white;
  }

  &--show {
    width: 55px;
    height: 23px;
    border-radius: 10px;
    border: rgba(0, 0, 0, 0.08);
    background-color: rgba(241, 241, 241, 1);
    font-size: 12px;
    color: rgba(0, 0, 0, 0.3);
  }
}

.list__content {
  li {
    list-style-type: disc;
    font-size: 14px;
    vertical-align: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--itemWrapper {
    display: flex;
    align-items: center;
  }

  &--settlement {
    width: auto;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &--name {
      width: auto;
      background-color: #f1f1f1;
      padding: 0 5px;
      border-radius: 10px;
    }
  }

  &--arrow {
    width: auto;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2px;
    cursor: pointer;
  }

  &--price {
    display: flex;
    align-items: center;
    justify-content: center;

    .customColor {
      color: rgba(21, 183, 145, 1);
    }
  }

  .offerListItem {
    display: flex;
    justify-content: space-between;
    &__wrapper-left {
      display: flex;
    }
    .badge--DDI {
      margin-left: 10px;
    }
  }

  &--days {
    color: rgba(255, 51, 51, 1);
    font-size: 12px;
  }

  &--investor {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    .badge--DDI {
      margin-left: 10px;
    }
  }

  &--name {
    position: relative;
    top: 1px;
    margin-left: 10px;
  }

  &--item {
    margin-left: 10px;
  }

  &--button {
    margin-left: 10px;
    cursor: pointer;
  }

  &--upload {
    margin-left: 30px;
  }

  &--plot {
    margin: 0 0 0 10px;
  }

  .blue_home {
    margin: 0 0 0 10px;
    width: 15px;
    flex-shrink: 0;
  }
  .customColor {
    color: rgba(21, 183, 145, 1);
  }
  .investor {
    width: 15px;
  }
}

h1 {
  margin-top: 10px;
}

.offers {
  &__slider {
    margin-top: 20px;

    &--item {
      padding-top: 4px;
    }

    .slick-list {
      padding: 0 7px !important;
    }

    .slick-slide {
      padding: 0 15px;
      font-weight: bold;
    }

    .active {
      background-color: black;
      color: white;
      font-size: 13px;
      height: 30px;
      font-weight: bold;
      margin: 0;
      transition: 0.1s linear;
      border-radius: 5px;
      padding: 0 12px;
    }
  }
}

.fullWidth {
  width: 80px;
}

.offerListItem {
  .badge {
    margin-left: 10px;
  }
}
