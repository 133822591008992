.notificationsRight {
  background-color: #fff;
  color: #000;

  &__header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 13px;
    .svg--exit {
      width: 12px;
    }
  }
  &__title {
    font-size: 15px;
    font-weight: $weight__bold;
    color: rgba(0, 0, 0, 0.65);
  }
  &__list {
    height: calc(100% - 80px);
    padding-left: 4px;
    overflow-y: scroll;
    overscroll-behavior: contain;
    padding-bottom: 30px;

    .fade-appear {
      opacity: 0;
      z-index: 1;
    }

    .fade-appear.fade-appear-active {
      opacity: 1;
      transition: opacity 300ms linear;
    }
  }
  &__list-item {
    height: 112px;
    display: flex;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.07);
    margin-bottom: 10px;
    cursor: pointer;
    transition: transform 0.15s ease-in;
    position: relative;
    // &:hover {
    //   transform: translateX(-15px);
    // }
    .opacity {
      opacity: 0.2;
      transition: opacity 300ms linear;
    }
  }

  .isDone {
    height: 90px;
  }

  &__list-item-click {
    position: absolute;
    width: 80%;
    top: 0;
    left: 0;
    height: 100%;
  }

  &__left {
    width: 61px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 12px 0;
  }
  &__right {
    width: calc(100% - 61px);
    box-shadow: -15px 0px 11px rgba(0, 0, 0, 0.04);
    padding: 7px 11px;
    // display: flex;
    // flex-direction: column;
  }
  &__hour {
    font-size: 14px;
    font-weight: $weight__medium;
  }

  &__weekday {
    font-size: 15px;
    font-weight: $weight__bold;
    color: rgba(0, 0, 0, 0.15);
    padding: 0 0 15px 24px;
  }
  &__user {
    background-color: rgba(241, 241, 241, 0.51);
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    display: inline-flex;
    padding: 4px 16px 4px 10px;
    font-size: 12px;
    font-weight: $weight__regular;
    svg {
      width: 13px;
      margin-right: 8px;
      .c {
        fill: rgba(163, 175, 240, 1);
      }
    }
  }

  &__plot {
    background-color: rgba(241, 241, 241, 1);
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    padding: 2px 8px 2px 5px;
    font-size: 12px;
    font-weight: $weight__regular;
    margin-top: 8px;
    width: 60px;
    svg {
      width: 13px;
      margin-right: 8px;
      .c {
        fill: rgba(163, 175, 240, 1);
      }
    }
  }
  &__description {
    font-size: 12px;
    font-weight: $weight__regular;
    color: rgba(0, 0, 0, 0.57);
    margin: 12px 0 0 8px;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .change__status {
      margin-top: 10px;
      width: 20px;
      height: 20px;
      .toDo {
        color: #fff;
        font-size: 1px;
        border-radius: 50%;
        border: 1px solid rgba(112, 112, 112, 0.09);
        width: 20px;
        height: 20px;
      }
    }
  }
  &__empty {
    text-align: center;
    font-size: 12px;
    font-weight: $weight__regular;
    color: rgba(0, 0, 0, 0.37);
    padding: 20px;
  }
  .employees-right__phone {
    width: 30px;
    height: 30px;
    svg {
      width: 11px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 60vh;
    position: fixed;
    top: calc(100vh - 59vh);
    left: 0;
    border-radius: 20px;
    animation-name: slideIn;
    animation-duration: 0.3s;
    z-index: 9;
  }

  @keyframes slideIn {
    from {
      top: 700px;
    }
    to {
      top: calc(100vh - 59vh);
    }
  }
}

.time_picker_modal_container {
  width: 300px !important;
  .time_picker_modal_header,
  span {
    font-size: 40px;
    color: #fff;
  }
}

.react_times_button {
  width: 129px;
  box-shadow: none !important;
  svg {
    display: none;
  }
  .preview_container {
    left: 44px;
    padding: 0 15px;
    background-color: #000000;
    color: #fff;
    font-weight: 500;
    border-radius: 30px;
    line-height: 20px;
    display: flex;
    height: 35px;
    align-items: center;
  }
  .wrapper {
    display: block;
    position: relative;
  }
}
