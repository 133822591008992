.tableWrapper {
  // margin-top: 10px;
  width: 100%;
  height: auto;
  font-size: 10px;
  // overflow: hidden;

  table {
    width: 100%;
    height: 100%;

    thead {
      background-color: white;
      position: sticky;
      top: 0;
      z-index: 1;

      tr {
        height: 25px;
      }

      th {
        text-overflow: ellipsis;
        margin: 0;

        .selectWrapper {
          div {
            div {
              height: 40px;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }

    // tbody {
    //   overflow: auto;
    // }

    tr {
      height: 60px;
      margin: 2px;
      td {
        min-width: 180px;
        background-color: rgba(246, 246, 246, 0.3);
        text-align: left;
        vertical-align: middle;
        left: 0px;
        span {
          margin-left: 12px;
          font-size: 10px;
        }
      }
    }
  }
}
