.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__left {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--info {
      width: 144px;
      height: 46px;
      margin-left: 20px;
      font-size: 10px;

      &--whichDoc {
        margin-bottom: 10px;
        font-weight: bold;
      }

      &--entries {
        font-size: 8px;
        display: inline-block;
        border-right: 1px solid #70707030;
        padding-right: 10px;
      }

      &--hiddenColumns {
        padding-left: 10px;
        font-size: 8px;
        display: inline-block;
        color: #70707030;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--box {
      margin-right: 15px;

      &--trigger {
        width: 124px;
        height: 25px;
        background: none;
        border: 1px solid #70707030;
        border-radius: 5px;
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        font-family: 'Poppins', sans-serif;
        cursor: pointer;
      }
    }
  }
}

.extend {
  width: 200px;
}

#green {
  background: #15b791;
  color: white;
  margin-left: 15px;
}

// hr {
//   width: 92vw;
//   height: 1px;
//   color: #70707030;
//   position: fixed;
//   top: 92vh;
//   opacity: 21%;
// }

.footer {
  height: 50px;
  display: flex;
  justify-content: flex-end;
  .amounts {
    // position: fixed;
    // width: 90vw;
    // top: 93vh;
    // display: flex;
    // justify-content: flex-end;
    display: flex;

    &__box {
      margin: 0 2px 0 2px;
      font-size: 10px;
      background: rgba(255, 51, 51, 0.03);
      width: 110px;
      text-align: right;
      opacity: 1 !important;

      &--title {
        color: rgba(0, 0, 0, 0.2);
        position: relative;
        background-color: rgba(0, 0, 0, 0.005);
      }

      &--number1 {
        position: relative;
        right: 10px;
        color: rgba(255, 51, 51, 1);
        font-size: 13px;
      }

      &--number2 {
        color: rgba(255, 51, 51, 1);
        position: relative;
        right: 10px;
      }
    }
  }
}

.setting-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dataManagmentTableWrapper {
  padding: 10px;
  height: calc(100vh - 200px);
  overflow: hidden;
  .reports__table--wrapper {
    height: 100%;
    width: 100%;
    overflow: auto;
    .sc-bdvvtL {
      height: 74vh;
    }
  }
}

.empty {
  text-align: center;
  margin: 50px auto;
  width: 100%;
}
