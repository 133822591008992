.select-field {
  padding: 10px 0 19px 0;
  &__label {
    font-size: 14px;
    font-weight: $weight__regular;
    color: #636363;
    margin-bottom: 5px;
  }
  &__option {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: $weight__regular;
    font-family: $font__poppins;
    .badge {
      margin-right: 15px;
    }
    &--active {
      color: #15b791;
      font-weight: $weight__bold;
    }
    &--inactive {
      color: #a8a8a8;
      font-weight: $weight__bold;
    }
  }
  &__input {
    &--error {
      .react-select__control {
        border: 1px solid rgba(255, 51, 51, 0.35) !important;
      }
    }
  }
  &__error-label {
    font-size: 11px;
    font-weight: $weight__regular;
    color: #ff3333;
    margin: 5px 0 0 10px;
    &-only2 {
      margin-top: 15px;
      margin-left: 2px;
    }
  }
  .react-select {
    &__control {
      background-color: #fff;
      border: none;
      box-shadow: 2px 4px 11px #00000014;
      flex-direction: row-reverse;
    }
    &__indicator-separator {
      display: none;
    }
    &__indicators {
      margin-left: 7px;
    }
    &__menu {
      box-shadow: 2px 4px 11px #00000014;
    }
    &__menu,
    &__single-value,
    &__placeholder,
    &__input {
      font-size: 12px;
      font-weight: $weight__regular;
      font-family: $font__poppins;
    }
    &__menu {
      z-index: 2;
    }
  }
}
