.header {
  // height: 163px;
  text-align: center;
  &__link {
    display: block;
    margin-top: 57px;
  }
  &__logo {
    height: 88px;
    margin: 0 auto;
  }
}
