.regular-field {
  &__container {
    position: relative;
    max-width: 266px;
    height: 40px;
    margin: 25px 0;
    &--full-width {
      max-width: 100%;
    }
  }
  &__label {
    position: absolute;
    top: -8px;
    left: 9px;
    padding: 0 5px;
    background-color: #fff;
    border-radius: 4px;
    font-size: 11px;
    font-weight: $weight__regular;
    color: $color__input-label;
    z-index: 1;
    &--error {
      color: $color__primary--red;
    }
    &--small {
      font-size: 12px;
    }
  }
  &__input {
    border: 1px solid $color__input-border;
    border-radius: 6px;
    height: 100%;
    width: 100%;
    padding: 0 10px;
    // font-size: 14px;
    font-weight: $weight__regular;
    display: flex;
    align-items: center;
    input {
      width: 100%;
      outline: none;
      appearance: none;
      font-size: 14px;
      font-weight: $weight__regular;
      font-family: $font__poppins;
      border: none;
    }

    &--error {
      border: 1px solid rgba($color__primary--red, 0.25) !important;
    }
    &--phone {
      width: 155px;
      position: relative;
      padding-left: 47px;
      &:after {
        content: '+48';
        color: rgba(17, 17, 17, 0.25);
        position: absolute;
        font-weight: $weight__regular;
        font-size: 14px;
        top: 50%;
        transform: translateY(-50%);
        left: 11px;
        width: 25px;
        line-height: 39px;
        height: 40px;
      }
    }
    &--provision {
      position: relative;
      padding-left: 30px;
      &:after {
        content: '%';
        color: rgba(17, 17, 17, 0.25);
        position: absolute;
        font-weight: $weight__regular;
        font-size: 14px;
        top: 50%;
        transform: translateY(-50%);
        left: 11px;
        width: 25px;
        line-height: 39px;
        height: 40px;
      }
    }
  }
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: 1px solid $color__input-border;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.group-label {
  color: rgba(99, 99, 99, 1);
  font-size: 14px;
  font-weight: $weight__regular;
}

.group-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .select-field {
    flex: 0 0 52%;
  }
  .regular-field {
    flex: 0 0 43%;
    margin-bottom: 8px;
    &__container {
      margin: 15px 0;
    }
  }
  input[type='file'] {
    display: none;
  }
}
